import React,{useState} from 'react'
import generateQR from '../../../assets/icons/generate_qr.png'
import odkCollect from '../../../assets/icons/odk_collect.png'
import disableQR from '../../../assets/icons/disable_qr.png';
import trailForm from '../../../assets/icons/trail_form.png'
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import checkIcon from '../../../assets/icons/check.png';
import {handleCreateOdkUser} from '../../../store/actions/odkAction'
import { getQR } from '../../../utils/GenerateQR';
import axios from 'axios';
import { handleCompletedTrialODK } from '../../../store/actions/profileAction';
function GS_OdkInfo({userWantInfoAgainFn,userWantInfoAgain}) {
  const user  = useSelector(state=>state.userInfo);
  const app  = useSelector(state=>state.appInfo);
  const BASE_URL = "https://htj5xy3nrv.us-east-1.awsapprunner.com";
  const dispatch = useDispatch();
  const [uri,setUri] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2,setModal2] = useState(false);
  const generatingQRCode = async () => {
    let data = {
      displayName: user.name,
      projectId: 1,
      phoneNumber: user.phoneNumber,
      location:user.location
    };
    dispatch(
      handleCreateOdkUser(data, (args) => {
        console.log("ARGS->", args);
        // getQR(args.token);
        setUri(getQR(args.token,user.location))
        toggle2()
      })
    );
  };

  const setUserWantInfoAgain = () =>{
    userWantInfoAgainFn(false)
}
const toggle = () =>{
  setModal(!modal)
};
const toggle2 = () =>{
  setModal2(!modal2)
};

  const handleRequestAccess = async (Form) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/nCount/odk/user-form-access`, {
        projectId: 1,
        formId: "tree_test_24",
        roleId: 2,
        userId: user.odkUserId,
        location:user.location
      })
      .then(function (response) {
        setLoading(false);
        toggle()
        updateTrialCompletion()
        // dispatch({ type: "UPDATE_ODK_FORM_ACCESS", payload: response.data });
      })
      .catch(function (error) {
        setLoading(false);
        setLoading(false);
        console.log(error);
      });
  };

  const updateTrialCompletion = async (base64Data) => {
    let data = {
      phoneNumber: user.phoneNumber,
      token:user.authToken,
      location:user.location
    };
    dispatch(
      handleCompletedTrialODK(data, (args) => {
        console.log("ARGS->", args);
      })
    );
  };

  return (
    <div>
      <div className='GS-Data-Collection-Container'>
      <div className='GS-Data-Collection-section'>
      <p className='GS-Data-Collection-Step-Number'>1</p>
      <p className='GS-Data-Collection-Step-Name'>Generate QR Code</p>
      {
        user?.odkToken?
      <center><img className='GS-Data-Collection-disable-QR' src={disableQR} /></center>
      :
      <center><img className='GS-Data-Collection-QR-Generator' onClick={()=>generatingQRCode()}  src={generateQR} /></center>
        }
      <p className='GS-Data-Collection-Step-desc-text'>
      Click on "Generate QR code". This is required for getting access to data collection form in the ODK App. 
      </p>
      </div>
      <div className='GS-Data-Collection-section'>
      <p className='GS-Data-Collection-Step-Number'>2</p>
      <p className='GS-Data-Collection-Step-Name'>Download ODK</p>
      <center>
      <a href="https://play.google.com/store/apps/details?id=org.odk.collect.android&hl=en&gl=US" target="_blank">
        <img className='GS-Data-Collection-QR-Generator' src={odkCollect} />
        </a>
        </center>
      <p className='GS-Data-Collection-Step-desc-text'>
      Click above to download ODK Collect App from Google Play Store.
      </p>
      </div>
      <div className='GS-Data-Collection-section'>
      <p className='GS-Data-Collection-Step-Number'>3</p>
      <p className='GS-Data-Collection-Step-Name'>Upload/ Scan the QR in ODK</p>
      <p className='GS-Data-Collection-Step-desc-text'>
      Upload/Scan the generated QR code using ODK Collect App to get download data collection form from nCount.
      </p>
      <p className='GS-Data-Collection-Step-Number' style={{textAlign:'start'}}>4</p>
      <p className='GS-Data-Collection-Step-Name' style={{textAlign:'start'}}>Try to access form and collect data</p>
     {
       user?.isTrialFormCompleted?
       <Button disabled className='Trail-form-access-disable' >{'Trial Form Access'}</Button>
       :
       <Button className={!user?.odkToken?'Trail-form-access-disable':app?.loading?'Loading...':'Trial-form-access' }onClick={!user?.odkToken?null:handleRequestAccess}>{loading?'Loading...':'Trial Form Access'}</Button>
     }
      {/* <img className='GS-Data-Collection-trail-form' src={trailForm} /> */}
   </div>
      </div>
      {
        user?.isTrialFormCompleted &&
      <p className='gs-continue-button' onClick={()=>setUserWantInfoAgain()}>Continue</p>
      }

<Modal style={{width:250,padding:0}} centered isOpen={modal} toggle={toggle}>
        <ModalBody>
          <center>
      <img src={checkIcon} style={{height:200,width:200}}/>
      <p className='gs-access-granted'>Access Granted</p>
          </center>
        </ModalBody>
      </Modal>
      <Modal style={{width:250,padding:0}} centered isOpen={modal2} toggle={toggle2}>
        <ModalBody>
          <center>
      <img src={uri} style={{height:200,width:200}}/>
       <div style={{display:'flex',justifyContent:'space-between'}}>
       <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Save QR</p>
        </a>
        <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Download QR</p>
              </a>
       </div>
          </center>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default GS_OdkInfo