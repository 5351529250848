import React from 'react'
import { Row } from 'reactstrap'

function CommingSoon() {
  return (
   <div>
    <Row style={{height:'65vh',justifyContent:'center',alignItems:'center'}}>
        <div>
<p className='comming-soon-stay-tuned'>Stay Tuned!</p>
<p className='comming-soon'>Coming Soon!</p>
        </div>
    </Row>
   </div>
  )
}

export default CommingSoon