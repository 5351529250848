import React from 'react'
import bg from '../assets/misc/contact-us-bg.png';
import { Col, Dropdown, Input, Row } from 'reactstrap';
import mail from '../assets/icons/mail.png';
import call from '../assets/icons/call.png';
import web from '../assets/icons/web.png';
import address from '../assets/icons/address.png'

function ContactUs() {
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
         <div className='main_container'>
     <img className="background-video" src={bg}/>
      <div className="content-1">
        <Row>
            <Col md="6">
            <Row>
            <Col md="7" className='contact-us-promt-leftside'>
            <p className='contact-us-title'>Contact Us</p>
            <p className='contact-us-desc'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.</p>
            <div className='contact-us-icons-links-div mt-2'>
                <img className='contact-us-icons' src={address}/>
           <p className='contact-us-link'>Address in detail</p>
            </div>
            <div className='contact-us-icons-links-div mt-2'>
            <img className='contact-us-icons' src={call}/>
           <p className='contact-us-link'>Phone Number</p>
           </div>
           <div className='contact-us-icons-links-div mt-2'>
           <img className='contact-us-icons' src={web}/>
           <p className='contact-us-link'>Link of the website</p>
           </div>
           <div className='contact-us-icons-links-div mt-2'>
           <img className='contact-us-icons' src={mail}/>
           <p className='contact-us-link'>Mail ID</p>
           </div>
            </Col>
            <Col md="5" style={{padding:'0px'}}>
            <div className='contact-form-row-1'>
    <p className='gap-data-form-title-1'>Suggest the Gap</p>
    <input className='gap-data-form-input'  placeholder='Email ID/ Mobile Number'/>
    <input className='gap-data-form-input' placeholder='Full Name'/>
    <input className='gap-data-form-input'  placeholder='Country'/>
    <Dropdown  placeholder={"I want to contribute to"}/>
    <Input
    style={{borderRadius:10,height:70,paddingLeft:0,marginBottom:20}}
    className='gap-data-form-input'
      id="exampleText"
      placeholder="Describe the data information Gap"
      type="textarea"
    //   value={extraDetail}
    //   onChange={(e)=>setExtraDetails(e.target.value)}
    />
    <button className='gap-data-form-button' >Submit</button>
</div>
            </Col>
            </Row>
            </Col>
        </Row>
    
        </div>
        </div>
    </div>

  )
}

export default ContactUs