export const predicted_geoserver_layers = [
    {
        id:"nCount:Individual_aesculusi_indica",
        sci_name:"Aesculusi indica",
        local_name:"Khanor"
    },
    {
        id:"nCount:Individual_bamboo",
        sci_name:"Bambusa bamboo",
        local_name:"Maggar"
    },
    {
        id:"nCount:Individual_berberis_aristate",
        sci_name:"Berberis aristate",
        local_name:"Kashmal"
    },
    {
        id:"nCount:Individual_chir_pine",
        sci_name:"Chir pine",
        local_name:"Chir"
    },
    {
        id:"nCount:Individual_dendrocalamus_strictus",
        sci_name:"Dendrocalamus strictus",
        local_name:"Bans"
    },
    {
        id:"nCount:Individual_eucalyptus",
        sci_name:"Eucalyptus",
        local_name:"Safeda"
    },
    {
        id:"nCount:Individual_indian_gooseberry",
        sci_name:"Indian gooseberry",
        local_name:"Amla"
    },
    {
        id:"nCount:Individual_lantana",
        sci_name:"Lantana",
        local_name:"Fulnu"
    },
    {
        id:"nCount:Individual_quercus_leucotrichophora",
        sci_name:"Quercus leucotrichophora",
        local_name:"Ban Oak"
    },
    {
        id:"nCount:Individual_rhododendron",
        sci_name:"Rhododendron",
        local_name:"Buransh"
    },
    {
        id:"nCount:Individual_senegalia_catechu",
        sci_name:"Senegalia catechu",
        local_name:"Khair"
    },
    {
        id:"nCount:Individual_shorea_robusta",
        sci_name:"Shorea robusta",
        local_name:"Sal"
    },
    {
        id:"nCount:Individual_cedrus_deodara",
        sci_name:"Cedrus deodara",
        local_name:"Deodar"
    },
    {
        id:"nCount:Individual_taxus_baccata",
        sci_name:"Taxus baccata",
        local_name:"Rakhal"
    },
    {
        id:"nCount:Individual_wild_mango",
        sci_name:"Wild mango",
        local_name:"Aam"
    },
]