import React, { useEffect, useState } from 'react'
import { Button, Nav,Navbar,NavbarBrand,NavbarText,NavItem,NavLink } from 'reactstrap';
import logo from '../../assets/logos/Header Logo.png'
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
function Header() {
  const [active,setActive] = useState(0);
  const location = useLocation();
  const user = useSelector(state=>state.userInfo);
  useEffect(()=>{
   console.log(location?.pathname)
   if(location?.pathname=='/'){
    setActive(0)
   }
   else if(location?.pathname=='/about'){
    setActive(1)
   }
   else if(location?.pathname=='/need-discovery'){
    setActive(2)
   }
   else if(location?.pathname=='/science'){
    setActive(3)
   }
   else if(location?.pathname=='/play'){
    setActive(4)
   }
   else{
    setActive(-1)
   }
  },[location?.pathname])
    const navLinkItem = [
        {
          navLinkName: "Home",
          targerRef: "/",
        },
        {
          navLinkName: "About Us",
          targerRef: "/about",
        },
        {
          navLinkName: "Need Discovery",
          targerRef: "/need-discovery",
        },
        {
          navLinkName: "Science",
          targerRef: "/science",
        },
        {
          navLinkName: "Play for good",
          targerRef: "/play",
        },
      ];
     
  return (
    
      user?.isCommForUser=="1" ?
      <Navbar className='header_Container'>
      <NavbarBrand>
       <h5 style={{color:'#22762B'}}>CommFor</h5>
      </NavbarBrand>
      <Nav
      className='headerCont'
      >
        
        </Nav>
        </Navbar>
      :
      <Navbar className='header_Container'>
      <NavbarBrand>
        <img className='header_logo' src={logo}  alt='ncount logo'/>
      </NavbarBrand>
      <Nav
      className='headerCont'
      >
       {navLinkItem.map((navItem,index) => {
                  return (
                    <NavItem>
                      <Link className='navitem-link' to={navItem.targerRef} onClick={()=>setActive(index)}>
                      <NavLink  className={index==active?'headerLinkActive':'headerLink'}>
                        {navItem.navLinkName}
                      </NavLink>
                      </Link>
                    </NavItem>
                  );
                })}
        
        </Nav>
        </Navbar>
    
   
  )
}

export default Header