import React, { useEffect, useState } from 'react'
import home_white from '../../../../assets/icons/home_white.png';
import home_green from '../../../../assets/icons/home_green.png';
import stories_white from '../../../../assets/icons/stories_white.png';
import event_white from '../../../../assets/icons/event_white.png';
import event_green from '../../../../assets/icons/event_green.png';
import stories_green from '../../../../assets/icons/stories_green.png';
import carbon_white from '../../../../assets/icons/carbon_white.png';
import carbon_green from '../../../../assets/icons/carbon_green.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';
function Header2() {
    const [activePage,setActivePage] = useState(0);
    const allLink = [{
        name :'Home',
        activeIcon:home_white,
        inactiveIcon:home_green,
        url:'/'
    },
    {
        name :'Event',
        activeIcon:event_white,
        inactiveIcon:event_green,
        url:'/mob/events'
    },
    {
        name :'Stories',
        activeIcon:stories_white,
        inactiveIcon:stories_green,
        url:'/mob/stories'
    },
    {
        name :'Sustainability',
        activeIcon:carbon_white,
        inactiveIcon:carbon_green,
        url:'/mob/sustainability'
    }
]

const changeActivePage = (index,url) =>{
    console.log(index)
    setActivePage(index)
    navigate(url)
}

console.log(activePage,"active page----")
const navigate = useNavigate();
const location = useLocation();

useEffect(()=>{
  if(location.pathname === '/'){
    setActivePage(0)
  }
  else if(location.pathname ==='/mob/events'){
    setActivePage(1)
  }
  else if(location.pathname ==='/mob/stories'){
    setActivePage(2)
  }
  else if(location.pathname ==='/mob/sustainability'){
    setActivePage(3)
  }
else{
   setActivePage(-1)
  }
},[location.pathname])
console.log("active icon ------",activePage)
  return (
    <div>
        <Row style={{justifyContent:'center',marginTop:50}}>
            
            {
                allLink.map((link,index)=>{
                  if(index==activePage){
                    return(
                        <div  className='mob-lower-header-icon-background'>
                        <div className='mob-lower-header-icon-background-green' onClick={()=>changeActivePage(index,link.url)}>
                            <img src={link?.activeIcon}   className='mob-lower-header-icon'/>
                        </div>
                            <p style={{width:'fit-content',fontSize:11,marginRight:14}}>{link?.name}</p>
                        </div>
                    )
                  }
                  else{
                    return(
                    <div className='mob-lower-header-icon-background' onClick={()=>changeActivePage(index,link.url)} style={{marginBottom:10}}>
                    <div className='mob-lower-header-icon-background-white'>
                        <img src={link?.inactiveIcon} style={index==0 ?{padding:2}:null}  className='mob-lower-header-icon'/>
                    </div>
                    <p style={{width:'fit-content',fontSize:11,marginRight:14}}>{link?.name}</p>
                    </div>
                    )
                  }
                })
            }
        </Row>
    </div>
  )
}

export default Header2