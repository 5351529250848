import axios from "axios";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  WMSTileLayer,
  LayersControl,
} from "react-leaflet";
import { useSelector } from "react-redux";
import { convertCsvToGeoJson } from "../../../utils/CsvToGeojson";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.css"; // Import clustering CSS
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import { getColorForSpecies } from "../../../utils/ColorGenerator";
import { predicted_geoserver_layers } from "../../../utils/Predicted_Layers";
import {
  scintificToLocal,
  scintificToScintific,
} from "../../../utils/SpeciesName";

function UserData({ isActive }) {
  const [geojsonData, setGeojsonData] = useState(null);
  const user = useSelector((state) => state?.userInfo);
  const [legendMinimized, setLegendMinimized] = useState(false);
  const [blockOdishaLayer,setBlockOdishaLayer] = useState(null);
  const [blockHpLayer,setBlockHpLayer] = useState(null);
  const mapRef = useRef(null); // Reference to the map container
  const latitude = 28.7041;
  const longitude = 77.10257;
  const { BaseLayer } = LayersControl;

  const fetchOdishaBlockLayer = async () => {
    try {
      const response = await axios.get("https://iofe-greenbase-bucket.s3.us-east-1.amazonaws.com/nCount/ncount-website-assests/geojson-layers/block_od.json");
      setBlockOdishaLayer(response.data);
    } catch (error) {
      console.error("Error fetching Odisha block layer data:", error);
    }
  };
  const fetchHpBlockLayer = async () => {
    try {
      const response = await axios.get("https://iofe-greenbase-bucket.s3.us-east-1.amazonaws.com/nCount/ncount-website-assests/geojson-layers/block_hp.geojson");
      setBlockHpLayer(response.data);
    } catch (error) {
      console.error("Error fetching Odisha block layer data:", error);
    }
  };

  const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  

  L.Marker.prototype.options.icon = DefaultIcon;

  const createClusterCustomIcon = (speciesName) => {
    const color = getColorForSpecies(speciesName);
    const bg = color;
    return (cluster) =>
      L.divIcon({
        html: `<div style="background: ${bg};height:40px;width:40px;border-radius:50%;display:flex;justify-content: center;align-items: center; "><span>${cluster.getChildCount()}</span></div>`,
        className: "customMarker",
        iconSize: L.point(40, 40, true),
      });
  };

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.invalidateSize(); // Ensure the map is resized when the tab becomes active
    }
  }, [isActive]);

  const getUserSubmissionData = async () => {
    try {
      const resp = await axios.post(
        "https://7gpaf9w48b.execute-api.us-east-1.amazonaws.com/collected-data",
        {
          userId: user?.odkUserId,
          // userId: "1366",
          userInsight: false,
        }
      );

      const geojson = convertCsvToGeoJson(resp?.data?.body);
      setGeojsonData(geojson);
    } catch (e) {
      console.error("Error", e);
    }
  };

  useEffect(() => {
    getUserSubmissionData();
    fetchOdishaBlockLayer();
    fetchHpBlockLayer();
  }, []);

  const onEachFeature = (feature, layer) => {
    try {
      if (feature.properties && feature.properties.speciesName) {
        const popupContent = `
                    <div>
                        <strong>Species:</strong> ${feature.properties.speciesName}
                    </div>
                `;
        layer.bindPopup(popupContent);
      }
    } catch (error) {
      console.error("Error binding popup:", error);
    }
  };

  const onBlockEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.Block_Na_1) {
      const popupContent = `<div>
      <p style="margin-bottom:-5px"> <strong>Location Details</strong></p>
       <p style="margin-bottom:-15px">
       <strong>State Name:</strong> ${feature.properties.state_name}</p>
       <p>
      <p style="margin-bottom:-15px">
       <strong>District Name:</strong> ${feature.properties.district_n}</p>
       <p>
      <strong>Block Name:</strong> ${feature.properties.Block_Na_1}</p></div>`;
      layer.bindPopup(popupContent);
    }
  };

  const filterBySpecies = (data, speciesName) => {
    return {
      type: "FeatureCollection",
      features: data.features.filter(
        (feature) => feature.properties.speciesName === speciesName
      ),
    };
  };

  const { uniqueSpecies, speciesCountMap, totalPoints } = useMemo(() => {
    const speciesCountMap = {};
    const uniqueSpeciesSet = new Set();
    let totalPoints = 0;

    if (geojsonData && geojsonData.features) {
      geojsonData.features.forEach((feature) => {
        const speciesName = feature.properties.speciesName;
        if (speciesName) {
          uniqueSpeciesSet.add(speciesName);
          if (!speciesCountMap[speciesName]) {
            speciesCountMap[speciesName] = 0;
          }
          speciesCountMap[speciesName]++;
          totalPoints++;
        }
      });
    }

    return {
      uniqueSpecies: [...uniqueSpeciesSet],
      speciesCountMap,
      totalPoints,
    };
  }, [geojsonData]);

  useEffect(() => {
    if (mapRef.current) {
      // Check if a legend is already added to avoid duplicates
      const existingLegend =
        mapRef.current._controlCorners?.bottomleft?.querySelector(
          ".info-legend"
        );
      if (existingLegend) {
        mapRef.current.removeControl(existingLegend);
      }

      const legend = L.control({ position: "bottomleft" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info-legend");

        const content = `
          ${uniqueSpecies
            .map((species) => {
              const color = getColorForSpecies(species);
              const count = speciesCountMap[species];
              const local_species_name = scintificToLocal[species];
              return `
                <div style="display:flex;align-items:center;margin-bottom:5px">
                  <span style="background-color: ${color}; width: 15px; height: 15px; display: inline-block; margin-right: 5px;"></span>
                  ${local_species_name}&nbsp;<i>(${scintificToScintific[species]})</i> &nbsp; - ${count} 
                </div>`;
            })
            .join("")}
        `;

        div.innerHTML = `
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <h6 style="margin: 0;color:#2b4f2f"><strong>Species</strong></h6>
            <button id="toggle-legend-btn" style="background: none; border: none; cursor: pointer; font-size: 1.5em;color:#2b4f2f">${
              legendMinimized ? "+" : "-"
            }</button>
          </div>
          <div id="legend-content" style="display: ${
            legendMinimized ? "none" : "block"
          };">
            <p style="margin-top: 10px;"><strong>Total Points:</strong> ${totalPoints}</p>
            ${content}
          </div>
        `;

        div
          .querySelector("#toggle-legend-btn")
          .addEventListener("click", () => {
            setLegendMinimized(!legendMinimized);
          });

        return div;
      };

      legend.addTo(mapRef.current);
    }
  }, [uniqueSpecies, speciesCountMap, totalPoints, legendMinimized]);
  const pointToLayer = (feature, latlng) => {
    const speciesName = feature.properties?.speciesName || "Unknown"; // Handle null or undefined speciesName

    const color = getColorForSpecies(speciesName);

    // Create a custom marker with divIcon
    const marker = L.marker(latlng, {
      icon: L.divIcon({
        html: `<div style="background: ${color}; height:20px; width:20px; border-radius:50%; display:flex; justify-content:center; align-items:center; font-size:12px; color:white;">${speciesName.charAt(
          0
        )}</div>`,
        className: "customMarker",
        iconSize: L.point(20, 20),
        iconAnchor: L.point(10, 10),
      }),
    });

    // Add events to the marker
    marker.on("click", () => {
      console.log(`Marker clicked for species: ${speciesName}`);
    });

    return marker;
  };
  const handleTileError = (error) => {
    console.error("WMS Tile Layer Error:", error);
  };

  const handleTileLoadStart = (event) => {
    console.log("WMS Tile Layer Load Start:", event);
  };

  const handleTileLoadEnd = (event) => {
    console.log("WMS Tile Layer Load End:", event);
  };
  const formatLayerName = (localName, sciName) => {
    return `${localName} (<i>${sciName}</i>)`;
  };

  return (
    <div style={{ height: "65vh", width: "100%", border: "1px solid #2b4f2f" }}>
      <MapContainer
        key={latitude + longitude}
        center={[latitude, longitude]}
        zoom={5}
        ref={mapRef}
        style={{ height: "100%", width: "100%" }}
      >
        <LayersControl position="topright" style={{textAlign:'left'}}>
          <BaseLayer checked name="CartoDB Light">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">CartoDB Light</a> contributors'
              url="https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}@2x.png"
            />
            <BaseLayer name="Google Satellite">
              <TileLayer
                attribution='&copy; <a href="https://www.google.org/copyright">Google</a> contributors'
                url="http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"
              />
            </BaseLayer>
          </BaseLayer>
          {predicted_geoserver_layers.map((layer) => {
            return (
              <LayersControl.Overlay
                checked={false}
                name={formatLayerName(layer?.local_name, layer?.sci_name)}
              >
                <WMSTileLayer
                  url="http://geoserver.ncount.in/geoserver/nCount/wms"
                  layers={layer?.id}
                  format="image/png"
                  transparent={true}
                  version="1.1.0"
                  opacity={0.5}
                  crs={L.CRS.EPSG4326}
                  eventHandlers={{
                    tileerror: handleTileError,
                    tileloadstart: handleTileLoadStart,
                    tileload: handleTileLoadEnd,
                  }}
                />
              </LayersControl.Overlay>
            );
          })}
            {blockHpLayer && (
            <LayersControl.Overlay checked name="Himachal Block Layer">
              <GeoJSON
                data={blockHpLayer}
                onEachFeature={onBlockEachFeature}
                style={{ color: "#ff7800", weight: 1 }}
              />
            </LayersControl.Overlay>
          )}
          {blockOdishaLayer && (
            <LayersControl.Overlay checked name="Odisha Block Layer">
              <GeoJSON
                data={blockOdishaLayer}
                onEachFeature={onBlockEachFeature}
                style={{ color: "#ff7800", weight: 1 }}
              />
            </LayersControl.Overlay>
          )}
        </LayersControl>
        {geojsonData &&
          uniqueSpecies.map((species) => (
            <MarkerClusterGroup
              key={species}
              chunkedLoading
              iconCreateFunction={createClusterCustomIcon(species)}
            >
              <GeoJSON
                data={filterBySpecies(geojsonData, species)}
                onEachFeature={onEachFeature}
                pointToLayer={pointToLayer}
              />
            </MarkerClusterGroup>
          ))}
      </MapContainer>
    </div>
  );
}

export default UserData;
