import React from 'react'
import NeedDicoveryComp from './NeedDicoveryComp'
import GapDataForm from './GapDataForm'
function NeedDiscovery() {
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
    <NeedDicoveryComp/>
    <GapDataForm/>
    </div>
  )
}

export default NeedDiscovery