import React from 'react'
import { Table } from 'reactstrap'

function DataCollection({questionaireData}) {
    console.log(JSON.parse(questionaireData[0]?.forestData))
    const questionKeyToCommforQuestions=[
        {
         legaleOwner:'Legal Owner of Forest',
         question1:'Do individuals from other (neighbouring) communities harvest products from this forest for self- consumption or market sale?',
         question2:'Do individuals in this community harvest products from other forests for self- consumption or market sale?',
         question3:'Does the community have rights to harvest products from the other forests for self-consumption or market sale?',
         question4:'What is the nature of forest management?',
         question5:'Rules exist to manage the forest',
         question6:' Are penalties imposed on users if they break rules?',
         question7:'Are penalties graduated?',
         question8:'What is the level of rule compliance by the users?',
         question9:'  What activities have been undertaken in this forest as part of management.',
         question10:'And by whom the activities have been undertaken in this forest as part of management.',
         question11:"And what is women's participation?",
         question12:'How is membership in the community-based forest management institution defined?',
         question13:'What is the gender of the current leader of the management institution?',
         question14:'Has a woman ever been a member of the leadership of the community-based forest management institution?',
         question15:'What is the frequency of meetings organized by the community-based forest management institution in the last one year?',
         question16:' What was the level of women’s participation in the meetings organized by the community-based forest management institution in the last one year?',
         
        }
    ]
    const emptyArray = [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]
  return (
   <div style={{height:'40vh',overflowY:'scroll',marginBottom:-100}}>
    <Table
>
  <thead>
    <tr>
      <th>
        
      </th>
      <th>
        questions
      </th>
      <th>
        Response
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">
        
      </th>
      <td>
        {questionKeyToCommforQuestions[0].legaleOwner}
      </td>
      <td>
       {JSON.parse(JSON.parse(questionaireData[0]?.forestData).legalOwner)}
      </td>
    </tr>
    {
      emptyArray.map((data,index)=>{
        if(index>=1){
        const question_index = 'question' + index;
        return (
            <tr>
      <th scope="row">
        
      </th>
      <td>
      {questionKeyToCommforQuestions[0][question_index]}
        {/* {'question'+index} */}
      </td>
      <td>
        {
            JSON.parse(questionaireData[0]?.forestData)[question_index]==[] ?
            <p>No Data</p>
            :
            (JSON.parse(questionaireData[0]?.forestData)[question_index])
        }
      </td>
    </tr>
        )
    }
      })
    }
     
  </tbody>
</Table>
   </div>
  )
}

export default DataCollection