import React from 'react'
import { Row } from 'reactstrap'
import gif from '../assets/misc/comming-soon-gif.gif'
function CommingSoon() {
  return (
    <div>
        <Row style={{justifyContent:'center',alignItems:'center'}}>
        <img className='comming-soon-gif' src={gif} alt="comming soon gif" />
        <p className='comming-soon-gif-text'>We are under construction. We wish to see you soon.</p>
        </Row>
    </div>
  )
}

export default CommingSoon