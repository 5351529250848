const INIT_STATE = {
    loading: false,
    error : null
  };

  const appInfo = (state=INIT_STATE,action) =>{

     switch(action.type){
        case 'ENABLE_LOADING' :
            return{
                ...state,
                loading:true
            }
        case 'DISABLE_LOADING' :
            return{
                ...state,
                loading:false
            }
        case 'SHOW_ERROR' :
            return{
                ...state,
                error:action.payload.response?.data

            }
        case 'REMOVE_ERROR' :
            return {
                ...state,
                error: null
            }
        default :
        return state;
     }
  }

  export default appInfo;
  