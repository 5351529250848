import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Navbar from './navbar/Header';
import bg_video from '../assets/misc/bg_video.mp4';
import Home from './home/Home';
import Footer from './navbar/Footer';
import Login from './auth/Login';
import Science from './sciencepage/Science';
import Team from './team/Team';
import NeedDiscovery from './needDiscovery/NeedDiscovery';
import Pfg from './pfg/Pfg';
import About from './about/About';
import TeamComp from './team/TeamComp';
import Dashboard from './dashboard/Dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Compaigns from './compaigns/Compaigns';
import GapTable from './gab-table/GapTable';
import Home_Activities from './home/Home_Activities';
import NAct from './NAct';
import ContactUs from './ContactUs';
import NFilms from './NFilms';
import Legal from './legal/Legal';
import Explore from './explore/nFold/Explore';

function Main() {
  const location = useLocation();
  const user = useSelector(state=>state.userInfo);
  const isDashboard = location.pathname === '/Dashboard' || location.pathname ==='/dashboard';
  const isLogin  = location.pathname === '/Login' || location.pathname ==='/login';
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch({type:'DISABLE_LOADING'})
  },[])
  return (
    <div className='main_container'>
      <video className="background-video" autoPlay={true} loop muted>
        <source src={bg_video} type="video/mp4" />
      </video>
      <div className="content">
        {!isDashboard && <Navbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login/>} />
          <Route path="/science" element={<Science />} />
          <Route path="/about" element={<TeamComp />} />
          <Route path="/need-discovery" element={<NeedDiscovery />} />
          <Route path="/play" element={<Pfg />} />
          <Route path='/compaigns' element={<Compaigns/>} />
          <Route path='/dashboard' element={<Dashboard/>} />
          <Route path="/gap-table" element={<GapTable/>}/>
          <Route path='/films' element={<NFilms/>}/>
          <Route path="/activities" element={<Home_Activities/>}/>
          <Route path="/n-act" element={<NAct/>}/>
          <Route path="/n-fold" element={<Explore/>}/>
          <Route path="/contact-us" element={<ContactUs/>}/>
          <Route path="/privacy-policy" element={<Legal/>}/> 
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  );
}

export default App;
