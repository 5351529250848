import React from "react";
import Select from "react-select";
import downIcon from '../assets/icons/down.png'
const Dropdown = ({ options, onChange,placeholder,placeholderStyle,inputStyle }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      ...inputStyle,
      "&:hover": {
        color:'black',
        // width:0
      },
      padding:0
    }),
    indicatorSeparator: () => ({
      display: "none",
      // padding:0
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#333",
      "&:hover": {
        background:"red",
        color: "#111",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      ...placeholderStyle ,
      marginRight:10,
      // marginLeft:-9
  // Apply custom placeholder styles
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused ? '#f0f0f0' : 'white',
      color:'black',
     // Apply custom background color on hover
      '&:hover': {
        color:'white',
        background: '#0a3b0a' // Apply custom background color on hover
      }
    }),
    input: (provided) => ({
      ...provided,
      paddingLeft: '-5',
      margin:'0',
    })
  };
  const handleReset = () => {
    onChange(null); // Propagate the change event with null value
  };

  return (
    <Select
      options={options}
      onChange={onChange}
      styles={customStyles}
      placeholder={placeholder}
      handleReset = {handleReset}
      components={{ DropdownIndicator }}
    />
  );
};

const DropdownIndicator = (props) => {
  return (
    <div {...props} style={{border:'none',paddingLeft:0}}>
      <p style={{marginBottom:0,marginLeft:10}}>
        <img style={{height:8,marginRight:10}} src={downIcon}/>
        </p>
    </div>
  );
};

export default Dropdown;
