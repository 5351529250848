import React from "react";
import game1 from "../../assets/pfg/game1.png";
import game2 from "../../assets/pfg/game2.png";
import { Col, Row } from "reactstrap";

function pfgCompUpdated() {
  return (
    <div style={{ width: "100vw", overflowX: "hidden" }}>
      <Row className="pfg-updated-row">
        <Col md="12" sm="12">
        <Row className="pfg-updated-row-2">
          <div className="pfg-updated-game1">
            <center>
              <p className="pfg-updated-game1-title">Consent</p>
              <div className="pfg-updated-div">
                <img src={game1} className="pfg-updated-game1-image" />

                <button className="pfg-updated-game1-button">Play</button>
                <p className="pfg-updated-game1-desc">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae architect.
                </p>
              </div>
            </center>
          </div>
          <div className="pfg-updated-game1">
            <center>
              <p className="pfg-updated-game1-title">VanaVerse</p>
              <div className="pfg-updated-div">
                <img src={game2} className="pfg-updated-game1-image" />

                <button className="pfg-updated-game1-button">Play</button>
                <p className="pfg-updated-game1-desc">
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae architect.
                </p>
              </div>
            </center>
          </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default pfgCompUpdated;
