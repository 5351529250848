import { combineReducers } from "redux";
import appUtils from "./entities/appUtils";
import userInfo from "./entities/AuthReducer";
import registerUserInfo from "./entities/RegisterUser";


const reducers = combineReducers({
    appInfo:appUtils,
    userInfo:userInfo,
    registerUserInfo:registerUserInfo
})

export default reducers;