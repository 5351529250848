import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateOdkUser } from '../../../store/actions/odkAction';
import { getQR } from '../../../utils/GenerateQR';
import disableQR from '../../../assets/icons/disable_qr.png'
import generateQR from '../../../assets/icons/generate_qr.png'
import odkCollect from '../../../assets/icons/odk_collect.png'
import axios from 'axios';
import { handleCompletedTrialODK } from '../../../store/actions/profileAction';
import { Button } from 'reactstrap';
import DataCollection from './data-collection/DataCollection';

function Main() {
  const user = useSelector(state=>state.userInfo);
  const app  = useSelector(state=>state.appInfo);
  const [activeButton,setActiveButton] = useState(0);
  const BASE_URL = "https://htj5xy3nrv.us-east-1.awsapprunner.com";
  const dispatch = useDispatch();
  const [uri,setUri] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCollectVisible,setCollectVisible] =useState(false);
  console.log("user===>",user)
  const generatingQRCode = async () => {
    let data = {
      displayName: user.name,
      projectId: 1,
      phoneNumber: user.phoneNumber,
    };
    dispatch(
      handleCreateOdkUser(data, (args) => {
        console.log("ARGS->", args);
        setUri(getQR(args.token,user?.location))
      })
    );
  };

  const GenerateQR = (token) => {
    setUri(getQR(token,user?.location));
  };
  useEffect(()=>{
    GenerateQR(user.odkToken)
  },[])
  useEffect(()=>{
    if(user?.isTrialFormCompleted){
      
    }
  },[])
  const handleRequestAccess = async (Form) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/nCount/odk/user-form-access`, {
        projectId: 1,
        formId: "tree_test_24",
        roleId: 2,
        userId: user.odkUserId,
        location:user.location
        
      })
      .then(function (response) {
        setLoading(false);
        updateTrialCompletion();
      })
      .catch(function (error) {
        setLoading(false);
        setLoading(false);
        console.log(error);
      });
  };

  const updateTrialCompletion = async (base64Data) => {
    let data = {
      phoneNumber: user.phoneNumber,
      token:user.authToken,
    };
    dispatch(
      handleCompletedTrialODK(data, (args) => {
        console.log("ARGS->", args);
      })
    );
  };
  const changePageActive = (idx) =>{
    setActiveButton(idx);
    setCollectVisible(false);
  }
  const makeCollectPageVisible = () =>{
    setCollectVisible(true);
    setActiveButton(-1);
  }
  
  const createUserFirst = () =>{
    alert("Please complete all the steps first.")
  }
  return (
    <div>
      <div style={{width:'80%'}} className='mb-3'>
        {(user?.odkToken && uri)&&
      <center>
      <img src={uri} style={{height:200,width:200}}/>
       <div style={{display:'flex',justifyContent:'space-evenly'}}>
       <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Save QR</p>
        </a>
        <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Download QR</p>
              </a>
       </div>
       </center>
}
      </div>
        <div className='mob-counter-div'>
        <a style={{textDecoration:'none'}} href='#1' onClick={()=>changePageActive(0)}>
            <p className={activeButton==0?'mob-counter-button-filled':'mob-counter-button-blank'}>1</p>
            </a>
            <a style={{textDecoration:'none'}} href='#2' onClick={()=>changePageActive(1)}>
            <p className={activeButton==1?'mob-counter-button-filled':'mob-counter-button-blank'}>2</p>
            </a>
            <a style={{textDecoration:'none'}} href='#3' onClick={()=>changePageActive(2)}>
            <p className={activeButton==2?'mob-counter-button-filled':'mob-counter-button-blank'}>3</p>
            </a>
            {
                user?.isTrialFormCompleted?
                <p className={isCollectVisible?'mob-counter-button-collect-visible':'mob-counter-button-collect'} onClick={()=>makeCollectPageVisible()}>Collect</p>
              :
              <p className='mob-counter-button-collect' onClick={()=>createUserFirst()}>Collect</p>
              }
        </div>
        {
          isCollectVisible?
          <DataCollection/>
          :
<>
        <div className='mob-GS-Data-Collection-Container'>
      <div className='mob-GS-Data-Collection-section' id="1">
      <p className='GS-Data-Collection-Step-Number'>1</p>
      <p className='GS-Data-Collection-Step-Name'>Generate QR Code</p>
      {
        user?.odkToken?
      <center><img className='GS-Data-Collection-disable-QR' src={disableQR} /></center>
      :
      <center><img className='GS-Data-Collection-QR-Generator' onClick={()=>generatingQRCode()}  src={generateQR} /></center>
        }
      <p className='GS-Data-Collection-Step-desc-text'>
      Click on "Generate QR code". This is required for getting access to data collection form in the ODK App. 
      </p>
      </div>
      </div>
      <div className='mob-GS-Data-Collection-section mt-3' id="2">
      <p className='GS-Data-Collection-Step-Number'>2</p>
      <p className='GS-Data-Collection-Step-Name'>Download ODK</p>
      <center>
      <a href="https://play.google.com/store/apps/details?id=org.odk.collect.android&hl=en&gl=US" target="_blank">
        <img className='GS-Data-Collection-QR-Generator' src={odkCollect} />
        </a>
        </center>
      <p className='GS-Data-Collection-Step-desc-text'>
      Click above to download ODK Collect App from Google Play Store.
      </p>
      </div>
      <div className='mob-GS-Data-Collection-section mt-3 mb-3' id="3">
      <p className='GS-Data-Collection-Step-Number'>3</p>
      <p className='GS-Data-Collection-Step-Name'>Upload/ Scan the QR in ODK</p>
      <p className='GS-Data-Collection-Step-desc-text'>
      Upload/Scan the generated QR code using ODK Collect App to get download data collection form from nCount.
      </p>
      <p className='GS-Data-Collection-Step-Number' style={{textAlign:'start'}}>4</p>
      <p className='GS-Data-Collection-Step-Name' style={{textAlign:'start'}}>Try to access form and collect data</p>
     {
       user?.isTrialFormCompleted?
       <Button disabled className='Trail-form-access-disable' >{'Trial Form Access'}</Button>
       :
       <Button className={!user?.odkToken?'Trail-form-access-disable':app?.loading?'Loading...':'Trial-form-access' }onClick={!user?.odkToken?null:handleRequestAccess}>{loading?'Loading...':'Trial Form Access'}</Button>
     }
      {/* <img className='GS-Data-Collection-trail-form' src={trailForm} /> */}
   </div>
   </>
        }
    </div>
  )
}

export default Main