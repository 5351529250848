import React from "react";
import { Container, Row, Col } from "reactstrap";

function TeamComp() {
  const allTeamMembers = [
      {
        name: "Abhijeet Parmar",
        ideology: "Concept.Design",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
        url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Abhijeet.jpg",
      },
      {
      name: "Abhinash Patra  ",
        ideology: "Spatial.Information",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
        url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/photo_abhinash.jpg",
      },
    {
      name: "Ashwini Chhatre",
      ideology: "Idea.Philosophy",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
      url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Ashwini_Chatre.jpg",
    },
    {
        name: "Bhumika Morey",
        ideology: "Spatial.Information",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
        url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Bhumika.jpg",
      },
    // {
    //   name: "Satya Prasanna",
    //   ideology: "Idea.Philosophy",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    //   url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Satya.png",
    // },
    // {
    //   name: "Sanjoy Mondal",
    //   ideology: "Leader.Field",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    //   url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Sanjoy.jpg",
    // },
  ];
  const allTeamMembers1 = [
    {
      name: "Ewan Thomas",
      ideology: "Data.Information",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
      url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Ewan.jpg",
    },
    {
      name: "Niharika Walia",
      ideology: "Design.Framework",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
      url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Niharika+Walia.jpg",
    },
    {
      name: "Nitesh Saini",
      ideology: "DevOps.Framework",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
      url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Nitesh+Saini.jpg",
    },
    {
        name: "Rohan Purkait",
        ideology: "DevOps.Framework",
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
        url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Rohan+Purkait.jpg",
      },
    // {
    //   name: "Siddharth Yadav",
    //   ideology: "DevOps.Framework",
    //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
    //   url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/siddharth.png",
    // },
  ];
  const allTeamMembers2 = [
    {
          name: "Sanjoy Mondal",
          ideology: "Leader.Field",
          desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
          url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Sanjoy.jpg",
        },
        {
              name: "Satya Prasanna",
              ideology: "Idea.Philosophy",
              desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
              url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Satya.png",
            },
    {
      name: "Siddharth Yadav",
      ideology: "DevOps.Framework",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud",
      url: "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/siddharth.png",
        },
   
  ];
  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <center>
          <h6 className="team-page-heading">
            nCount <span className="team-page-title">Journey</span>
          </h6>
        </center>
        <div className="all-team-members-container" style={{ marginTop: 30 }}>
          <Row>
            <Col md="1"></Col>
            <Col md="10">
              <p className="ncount-journey-description">
                nCount represents a cutting-edge approach to large scale
                prosperity and social well-being, through environmental
                monitoring and conservation. nCount is specifically designed to
                engage a diverse range of users, including forest-dwelling
                communities, citizen scientists, school and college students,
                nature enthusiasts, and researchers. Through an intuitive and
                user-friendly interface, participants can actively contribute to
                the scientific understanding of biodiversity by documenting
                plant and animal species in their local ecosystems. nCount
                harnesses the power of Artificial Intelligence (AI) and computer
                vision to assist in the identification and classification of
                species from images, ensuring accurate and efficient data
                processing.
              </p>
              {/* <p className='ncount-journey-description'>
Furthermore, the integration of 3D gaming technology and Augmented Reality (AR) enhances the user experience and educational aspects of the platform. School and college students, in particular, can immerse themselves in a virtual environment that replicates real-world ecosystems, allowing for interactive learning experiences. This gamified approach not only makes the process of data collection more engaging but also serves as an educational tool, fostering a deeper understanding of ecological concepts and the importance of biodiversity. Additionally, nCount extends its reach to empower forest-dwelling communities, enabling them to actively participate in forest inventory activities, contributing valuable local knowledge and strengthening their role in sustainable forest management. Overall, nCount represents a holistic and inclusive approach to biodiversity monitoring and environmental education, leveraging advanced technologies to bridge the gap between scientific research and community engagement.</p>  */}
            </Col>
            <Col md="1"></Col>
          </Row>
        </div>
        <center>
          <h6 className="team-page-heading">
            Meet Our <span className="team-page-title">Team</span>
          </h6>
        </center>

        <Row
          style={{ justifyContent: "center", marginBottom: 170, marginTop: 70 }}
        >
          <Col md="3">
            <div className="team-member-box">
              <center>
                <div className="team-image-box">
                  <img
                    className="team-image"
                    src={
                      "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/You.jpg"
                    }
                    alt={"You"}
                  />
                </div>
              </center>
              <div className="team-desc-box">
                <div className="team-member-desc-box">
                  <p className="team-member-title">{"You"}</p>
                  <p className="team-member-info">{""}</p>
                  <p className="team-member-info">{""}</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="all-team-members-container">
          <Row>
            {allTeamMembers.map((teamMember) => {
              return (
                <Col md="3">
                  <div className="team-member-box">
                    <div className="team-image-box">
                      <img
                        className="team-image"
                        src={teamMember.url}
                        alt={teamMember.name}
                      />
                    </div>
                    <div className="team-desc-box">
                      <div className="team-member-desc-box">
                        <p className="team-member-title">{teamMember.name}</p>
                        <p className="team-member-desc">
                          {teamMember.ideology}
                        </p>
                        <p className="team-member-info">{teamMember.desc}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div style={{ marginTop: 170 }} className="all-team-members-container">
          <Row>
            {allTeamMembers1.map((teamMember) => {
              return (
                <Col md="3">
                  <div className="team-member-box">
                    <div className="team-image-box">
                      <img
                        className="team-image"
                        src={teamMember.url}
                        alt={teamMember.name}
                      />
                    </div>
                    <div className="team-desc-box">
                      <div className="team-member-desc-box">
                        <p className="team-member-title">{teamMember.name}</p>
                        <p className="team-member-desc">
                          {teamMember.ideology}
                        </p>
                        <p className="team-member-info">{teamMember.desc}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
        <div style={{ marginTop: 170 }} className="all-team-members-container">
          <Row>
            {allTeamMembers2.map((teamMember) => {
              return (
                <Col md="4">
                    {
                        teamMember?.name!=''
                &&
                  <div className="team-member-box">
                    <div className="team-image-box">
                      <img
                        className="team-image"
                        src={teamMember.url}
                        alt={teamMember.name}
                      />
                    </div>
                    <div className="team-desc-box">
                      <div className="team-member-desc-box">
                        <p className="team-member-title">{teamMember.name}</p>
                        <p className="team-member-desc">
                          {teamMember.ideology}
                        </p>
                        <p className="team-member-info">{teamMember.desc}</p>
                      </div>
                    </div>
                  </div>
            }
                </Col>
              );
            })}
          </Row>
        </div>
        <center>
          <h6 style={{ marginTop: 150 }} className="team-page-heading">
            <span className="team-page-title">Friends of  nCount</span>
          </h6>
        </center>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{ marginTop: 80, marginBottom: 100 }}
            className="all-team-members-container"
          >
            <div className="team-member-box-partner">
              <div className="team-image-box-partner">
                <img
                  className="team-image-partner"
                  src={
                    "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/HP.png"
                  }
                  alt="Delhi University"
                />
              </div>
              <div className="team-desc-box-partner">
                <div className="team-member-desc-box-partner">
                  <p className="team-member-title-partner">
                    {"Himachal Pradesh"}
                  </p>
                  <p className="team-member-desc-partner">
                    {"Forest Department"}
                  </p>
                  {/* <p className='team-member-info'>{teamMember.desc}</p> */}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ marginTop: 80, marginBottom: 100 }}
            className="all-team-members-container"
          >
            <div className="team-member-box-partner">
              <div className="team-image-box-partner">
                <img
                  className="team-image-partner"
                  src={
                    "https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/DU.png"
                  }
                  alt="Delhi University"
                />
              </div>
              <div className="team-desc-box-partner">
                <div className="team-member-desc-box-partner">
                  <p className="team-member-title-partner">
                    {"Delhi University"}
                  </p>
                  <br />
                  {/* <p className='team-member-desc-partner'>{'Forest Department'}</p> */}
                  {/* <p className='team-member-info'>{teamMember.desc}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamComp;
