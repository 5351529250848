import React, { useState } from 'react'
import { Button, Table } from 'reactstrap'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
function SpeciesData({speciesData}) {
    console.log(speciesData.length)
    const [modal, setModal] = useState(false);
     const [imageUri,setImageUri] = useState(null);
     const [speciesName,setSpeciesName] = useState(null)
     const [speciesNameType,setSpeciesNameType] = useState(null);
    const toggle = () => setModal(!modal);
    const openModel = (imageUri,speciesName,type)=>{
        toggle()
        setImageUri(imageUri)
        setSpeciesName(speciesName)
        setSpeciesNameType(type)
    }
  return (
    <div style={{height:'40vh',overflowY:'scroll',marginBottom:-100}}>
        {
            speciesData.length>0
        ?
        <Table
>
  <thead>
    <tr>
      <th>
      Species Name
      </th>
      <th>
      Species Type
      </th>
      <th>
        Image
      </th>
    </tr>
  </thead>
  <tbody>
        {
            speciesData.map((data)=>{
                return (
                    <tr>
                    <td>
                    {data?.speciesName}
                  </td>
                  <td>
                    {data?.speciesNameType}
                  </td>
                  <td>
                  <p className='signIn-button profile-sign-out mt-0' onClick={()=>openModel(data?.imageUri,data?.speciesName,data?.speciesNameType)} style={{width:170}}>View Photo</p>
                  </td>
                </tr>
                )
            })
        }
  </tbody>
</Table>
:
<center>No Data Found.</center>
}
<Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{speciesName} ({speciesNameType})</ModalHeader>
        <ModalBody>
            <img src={imageUri}  style={{width:'100%',objectFit:'cover'}}/>
            </ModalBody>
            </Modal>
            
    </div>
  )
}

export default SpeciesData