const REGISTER_STATE = {
    firstName:null,
    lastname:null,
    phoneNumber:null
  }

  const registerUserInfo = (state = REGISTER_STATE, action) => {
    switch (action.type) {
      case "SET_REGISTER_USER_INFO":
        return{
            ...state,
            firstName : action.payload.firstName,
            lastName : action.payload.lastName,
            phoneNumber : action.payload.phoneNumber
        }
    case "REMOVE_REGISTER_USER_INFO" :
        return {
            ...state,
            firstName:null,
    lastname:null,
    phoneNumber:null
        }

        default:
            return state;
    }
      };
      
export default registerUserInfo;