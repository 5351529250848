import React, { useEffect, useState } from 'react'
import Header from '../header/Header'
import { Col, Row } from 'reactstrap'
import { useDispatch } from 'react-redux';
import { handleGetDetails } from '../../../../store/actions/utilAction';
import Header2 from '../header/Header2';
import MobFooter from '../../footer/Footer';

function LandingPage() {
    const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(
      handleGetDetails((args) => {
        console.log("ARGS->", args);
        let newCounterData = [
          {
            id: 0,
            name: "SPECIES",
            count: args?.totalspecies,
            prev: parseInt(args?.totalspecies)-1,
            next: parseInt(args?.totalspecies)+1,
          },
          {
            id: 1,
            name: "CITIZENS SCIENTISTS",
            count: args?.totalcollector,
            prev: parseInt(args?.totalcollector)-10,
            next: parseInt(args?.totalcollector)+10,
          },
          {
            id: 2,
            name: "CONTRIBUTIONS",
            count: args?.totalcontributions,
            prev: parseInt(args?.totalcontributions)-1000,
            next: parseInt(args?.totalcontributions)+1000,
          },
        ]
        setCounterData(newCounterData)
      })
    );
  },[])
  const [bit, setBit] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (counterActiveNumber == 2) setCounterActiveNumber(0);
      else setCounterActiveNumber(counterActiveNumber + 1);
      setBit(!bit);
    }, 2000);
  }, [bit]);
  const allCounterData = [
    {
      id: 0,
      name: "SPECIES",
      count: 24,
      prev: 23,
      next: 25,
    },
    {
      id: 1,
      name: "CITIZENS SCIENTISTS",
      count: 270,
      prev: 260,
      next: 280,
    },
    {
      id: 2,
      name: "CONTRIBUTIONS",
      count: 40000,
      prev: 39000,
      next: 410000,
    },
  ];
  const [counterData, setCounterData] = useState(allCounterData);
  const [counterActiveNumber, setCounterActiveNumber] = useState(0);
  return (
    <div>
        {/* <Header/> */}
        <Row >
            <p className='mob-landingpage-title'>
            Combining emerging technology 
and systems approach 
for people-centered sustainability 
action at scale!
            </p>
            <Col md="6" className="home_mid_section_container">
            <div className="home_mid_section mob_section_home_mid_section">
              <div className="home_mid_section_bg">
                        <p className="counter-title-mob"> {counterData[counterActiveNumber].name}</p>
                <div className="home-mid-section-text-box">
                  <center>
                    <div className="dynamic-counter">
                      <div className="dyanmic-counter-count-prev">
                        <p>{counterData[counterActiveNumber].prev}</p>
                        {/* <p className="counter-title">
                          {counterData[counterActiveNumber].name}
                        </p> */}
                      </div>
                      <div className="dyanmic-counter-count-current">
                        <p>{counterData[counterActiveNumber].count}</p>
                      </div>
                      <div className="dyanmic-counter-count-next">
                        <p>{counterData[counterActiveNumber].next}</p>
                      </div>
                    </div>
                  </center>
                    
                </div>
                <center>
                  {/* <Button className="homeButton">Explore</Button> */}
                </center>
              </div>
            </div>
          </Col>
        </Row>
        {/* <Header2/>
        <MobFooter/> */}
    </div>
  )
}

export default LandingPage