import React from 'react'
import { Row } from 'reactstrap'
import CommingSoon from '../../../components/CommingSoon'

function Stories() {
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}><CommingSoon/></div>
  )
}

export default Stories