import React from 'react'
import bgImage from '../../assets/home/home_activities_bg.png';
import demoImage from '../../assets/home/home_activities_demo.png';
import { Row } from 'reactstrap';
import CommingSoon from '../CommingSoon';
function Home_Activities() {
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
        <Row>
          <CommingSoon/>
          
         {/* <img src={bgImage}/>
         <div className='home-activities-text-box'>
            <p>30’ May</p>
            <p>nCount Workshop (Himachal Pradesh)</p>
         </div> */}
        </Row>
    </div>
  )
}

export default Home_Activities