import React,{useEffect, useState,useRef } from 'react'
import { Container,Col,Row, Input,Button} from 'reactstrap'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {handleLoginUser} from '../../../../store/actions/authAction'
function Login() {
  const user= useSelector(state=>state.userInfo);
  useEffect(()=>{
     if(user?.userId){
      navigate('/mob/dashboard')
     }
  },[user])
  console.log("user",user)

  const redirectToSignup = () => {
    // window.location.href = 'http://54.91.139.129/auth/';
    const popup = window.open('https://authenticator.ulogged.in/', 'oauth', 'height=720,width=480');

  };
  const redirectToUpdatePassword = () => {
    // window.location.href = 'http://54.91.139.129/auth/';
    const popup = window.open('https://authenticator.ulogged.in/forgot-password', 'oauth', 'height=720,width=480');

  };
  var appInfo = useSelector((state) => state.appInfo);
  const timer = useRef();
  const navigate= useNavigate()
  const dispatch = useDispatch()
  const [phoneNumber,setPhoneNumber]  = useState('');
  const [pin,setPin] = useState('');
  const [error, setError] = useState("");
  // console.log("Loading....",appInfo.loading)

  const handleLogin = () => {
    // setappInfo.loading(true);
    console.log('logging....')
    console.log("function login called");
    if (phoneNumber === '' || pin === '') {
      // setappInfo.loading(false);
      setError("*Please Fill All the Fields!!");
      timer.current = setTimeout(resetError, 1500);
    } else {
      let user = {
        phoneNumber: phoneNumber,
        password: pin,
      };
      dispatch(
        handleLoginUser(user, (args) => {
          
          console.log("ARGS->", args);
          // setappInfo.loading(false);
          if (!args.user.id) {
            setError(appInfo.error);
            console.log("errorrrrrrrrrrrrrrrr");
          }
          else{
            // setappInfo.loading(false);
            navigate("/mob/dashboard");
          }
        })
      );
    }
  };

  const resetError = () => {
    setError("");
    clearTimeout(timer);
  };
  return (
    <div>
        <Container>
         <Row className='login-section-row' style={{marginLeft:0,marginRight:0}}>
            <Col md="4" sm="10" xs="12" className='mob-login-section-container'>
                <div className='login-section-column'>
                <p className='login_text_heading'>Login</p>
                <Input type="text" autocomplete="off" className='login-pin-input' pattern="[0-9]{10}" onChange={(e)=>setPhoneNumber(e.target.value)} placeholder='Enter Phone Number'/>
                <Input type='password' autocomplete="off" className='login-pin-input' placeholder='Enter your PIN ' onChange={(e)=>setPin(e.target.value)} />
                <Button className='login-submit' onClick={()=>handleLogin()}>{appInfo?.loading ? 'Loading...' : 'Login'}</Button>
                {
                  appInfo?.error && <p className='api-error'>{appInfo?.error}</p>
                }
                {
                  error && <p className='api-error'>{error}</p>
                }
                <p className='login-forgot-password' onClick={()=>redirectToUpdatePassword()}>Forgot Password?</p>
                <p className='login-new-user-register' onClick={()=>redirectToSignup()}>New User? Register Now.</p>
                </div>
            </Col>
         </Row>
        </Container>
    </div>
  )
}

export default Login