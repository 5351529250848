import React, { useState } from 'react'
import allForms from '../../../utils/AllForms.json';
import { Col, Row,Tooltip} from 'reactstrap';
import infoIcon from '../../../assets/icons/info.png'
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
function GS_Data_Collection_Forms({userWantInfoAgain}) {
    const BASE_URL = "https://htj5xy3nrv.us-east-1.awsapprunner.com";
    const dispatch = useDispatch();
    const user = useSelector((state) => state.userInfo);
    const appInfo = useSelector(state=>state.appInfo);
    const [loading, setLoading] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [modal, setModal] = useState(false);
    const [formPin,setFormPin] =useState('')
    const toggle = (form) => {
      setSelectedForm(form)
      setModal(!modal);
    }
    const setUserWantInfoAgain = () =>{
        userWantInfoAgain(true)
    }
  
    //   Request Access
const handleRequestAccess = async (Form) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/nCount/odk/user-form-access`, {
        projectId: Form.projectId,
        formId: Form.formId,
        roleId: Form.roleId,
        userId: user.odkUserId,
        location:user.location
      })
      .then(function (response) {
        setLoading(false);
        alert("You have the form access now.");
        setFormPin('')
        // dispatch({ type: "UPDATE_ODK_FORM_ACCESS", payload: response.data });
        setSelectedForm(null);
      })
      .catch(function (error) {
        setLoading(false);
        alert("You already have this form access.");
        setFormPin('')
        toggle()
        setSelectedForm(null);
        setLoading(false);
        console.log(error);
      });
  };

  const handleRequestFormAccess = async () =>{
     console.log(selectedForm?.pin,formPin)
     if(selectedForm?.pin==formPin || formPin=='admin_ncount'){
      await handleRequestAccess(selectedForm)
     }
     else{
      alert("You have entered the wrong pin!");
      setFormPin('')
     }
  }

  return (
    <>
    <div className='gs-data-collection-forms-div'>
        <Row>
    {
        allForms.map((form)=>{
          console.log(form.location,user.location)
     
            return (
                <Col md="4">
               <div className='gs-formname-div' onClick={()=>toggle(form)}>
                <p className='gs-formname-text1'>{!(loading && form.formId==selectedForm?.formId)?form.name:'Loading...'}</p>
                <p className='gs-formname-text2'>{form.name2}</p>
               </div>
            </Col>
            )
   
            })
            }
        </Row>
        <div className='gs-info-icon-div'>
            <img className='gs-info-icon' src={infoIcon} onClick={()=>setUserWantInfoAgain()}/>
            </div>
    </div>
    <Modal isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>Modal title</ModalHeader> */}
        <ModalBody>
        <center>
{/* <h6 className="mt-3 mb-4">Mobile Camera Specification Info</h6> */}
        <input
            className="login-pin-input profile-input mt-5 mb-4"
            style={{width:'100%',borderRadius:10}}
            value={formPin}
            onChange={(e)=>setFormPin(e.target.value)}
            placeholder="Please provide pin"
            />
              <button
            className="profile-update-button mt-3 mb-1"
            style={{paddingTop:5,paddingBottom:5,paddingLeft:15,paddingRight:15,color:'white'}}
            onClick={handleRequestFormAccess}
          >
            {loading ? 'Loading...':'Request Access'}
          </button>
            </center>
        </ModalBody>
      </Modal>
    </>
  )
}

export default GS_Data_Collection_Forms