import React from 'react'
import ScienceComponent from './ScienceComponent'
import ScienceComp2 from './ScienceComp2'

function Science() {
  return (
    <ScienceComp2/>
  )
}

export default Science