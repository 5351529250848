import { request } from "./ApiCentral";

export const loginUser = (data) =>{
    console.log(data, " login user")
    return request(
        '/user/uloggedin-login',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}


export const registerUser = (data) =>{
    console.log(data, " login user")
    return request(
        '/user/user-pre-registeration',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}

export const verifyOtp = (data) =>{
    console.log(data, " login user")
    return request(
        '/user/verify-otp',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}

export const completeRegisteration = (data) =>{
    console.log(data, " login user")
    return request(
        '/user/user-registeration',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}

export const forgetPassword  = (data) =>{
  console.log(data, "user details")
  return request(
    '/user/forgot-password',
    {
      method: 'POST',
      data,
    },
    false,
    false,
  );
}

export const updatePassword  = (data) =>{
  console.log(data, "user details")
  return request(
    '/user/update-password',
    {
      method: 'POST',
      data,
    },
    false,
    false,
  );
}
