import { request } from "./ApiCentral";

export const updateProfile = (data) =>{
    console.log(data, " update user data")
    return request(
        '/profile/update-profile',
        {
          method: 'POST',
          data,
        },
        false,
        data.token,
        false,
      );
}

export const uploadProfileImage = (data) =>{
  console.log(data, " update user data")
  return request(
      '/profile/update-profile-image',
      {
        method: 'POST',
        data,
      },
      false,
      data.token,
      false,
    );
}

export const getProfile = (data) =>{
  console.log(data, " get user profile")
  return request(
      '/profile/getProfile',
      {
        method: 'POST',
        data,
      },
      false,
      data.token,
      false,
    );
}

export const trailFormCompleted = (data) =>{
  console.log(data, " get user profile")
  return request(
      '/profile/odk-trail-completed',
      {
        method: 'POST',
        data,
      },
      false,
      data.token,
      false,
    );
}
