import React, { useEffect, useState } from 'react'
import { Button, Nav,Navbar,NavbarBrand,NavbarText,NavItem,NavLink } from 'reactstrap';
import logo from '../../assets/logos/Header Logo.png'
import default_profile from '../../assets/icons/userDemo.png'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
function Header() {
  const user = useSelector(state => state.userInfo);
  console.log(user)
  const [active,setActive] = useState(0);
  const location = useLocation();
  useEffect(()=>{
   console.log(location?.pathname)
   if(location?.pathname=='/'){
    setActive(0)
   }
   else if(location?.pathname=='/about'){
    setActive(1)
   }
   else if(location?.pathname=='/need-discovery'){
    setActive(2)
   }
   else if(location?.pathname=='/science'){
    setActive(3)
   }
   else if(location?.pathname=='/play'){
    setActive(4)
   }
   else{
    setActive(-1)
   }
  },[location?.pathname])
   
    const navLinkItem = [
        {
          navLinkName: "Home",
          targerRef: "/",
        },
        {
          navLinkName: "About Us",
          targerRef: "/about",
        },
        {
          navLinkName: "Need Discovery",
          targerRef: "/need-discovery",
        },
        {
          navLinkName: "Science",
          targerRef: "/science",
        },
        {
          navLinkName: "Play for good",
          targerRef: "/play",
        },
      ];
     
  return (
    <Navbar className='header_Container'>
    <NavbarBrand>
      <img className='header_logo' src={logo}  alt='ncount logo'/>
    </NavbarBrand>
    <Nav
    className='headerCont'
    >
     {navLinkItem.map((navItem,index) => {
                return (
                  <NavItem>
                    <Link className='navitem-link' to={navItem.targerRef} onClick={()=>setActive(index)}>
                    <NavLink  className={index==active?'headerLinkActive':'headerLink'}>
                      {navItem.navLinkName}
                    </NavLink>
                    </Link>
                  </NavItem>
                );
              })}
      
      </Nav>
      <NavbarText>
        {
          (user?.userId)?
          <Link style={{textDecoration:'none'}} to='/dashboard' onClick={()=>setActive(-1)}>
       
          <img className='navbar-user-profile' src={default_profile}/>
       
        </Link>
        :
        <Link style={{textDecoration:'none'}} to='/login' onClick={()=>setActive(-1)}>
        <p className='signIn-button'>Sign In</p>
        </Link>
        }
      </NavbarText>
      </Navbar>
  )
}

export default Header