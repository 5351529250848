import { completeRegisteration, forgetPassword, loginUser, registerUser, updatePassword, verifyOtp } from "../services/AuthServices";

export const handleLoginUser = (data, callback) => (dispatch) => {
  dispatch({ type: "ENABLE_LOADING" });
  return loginUser(data)
    .then(async ({ data: response }) => {
      console.log("RES", response);
      callback(response);
      dispatch({ type: "SET_USER_INFO", payload: response });
      dispatch({ type: "SHOW_ERROR", payload: null });
      dispatch({ type: "DISABLE_LOADING" });
      if (callback) {
        dispatch({ type: "DISABLE_LOADING" });
      }
    })
    .catch((err) => {
      console.log("NETWORK ERROR", err);
      dispatch({ type: "DISABLE_LOADING" });
      dispatch({ type: "SHOW_ERROR", payload: err });
    });
};

export const handleRegisterUser = (data, callback) => (dispatch) => {
  dispatch({ type: "ENABLE_LOADING" });
  return registerUser(data)
    .then(async ({ data: response }) => {
      callback(response);
      console.log("RES from registeration", response);
      dispatch({ type: "SHOW_ERROR", payload: null });
      dispatch({ type: "DISABLE_LOADING" });
      if (callback) {
        dispatch({ type: "DISABLE_LOADING" });
      }
    })
    .catch((err) => {
      console.log("NETWORK ERROR", err);
      dispatch({ type: "DISABLE_LOADING" });
      dispatch({ type: "SHOW_ERROR", payload: err });
    });
};

export const handleVerifyOtp = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return verifyOtp(data)
      .then(async ({ data: response }) => {
        callback(response);
        console.log("RES from registeration", response);
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleCompleteRegisteration = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return completeRegisteration(data)
      .then(async ({ data: response }) => {
        callback(response);
        console.log("RES from registeration", response);
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleForgetPassword = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return forgetPassword(data)
      .then(async ({ data: response }) => {
        callback(response);
        console.log("RES from registeration", response);
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleUpdatePassword = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return updatePassword(data)
      .then(async ({ data: response }) => {
        callback(response);
        console.log("RES from registeration", response);
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };
 
  