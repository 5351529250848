import { Button } from 'bootstrap'
import React, { useState } from 'react'
import { Row } from 'reactstrap'

function CommForDashboard() {
    const [copySuccess, setCopySuccess] = useState('');
    const linkToCopy = 'https://ncount.in/dashboard';
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(linkToCopy).then(() => {
        setCopySuccess('Link copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear message after 2 seconds
      }, () => {
        setCopySuccess('Failed to copy!');
      });
    };
  return (
    <div>
        <Row style={{justifyContent:'center',alignItems:'center',height:'70vh',display:'flex',flexDirection:'row'}}>
            <div style={{width:'auto',textAlign:'center'}}>
            <p>Please open this link on your desktop.</p>
             <p>https://ncount.in/dashboard</p>
             <button style={{marginTop:-2}} className='mob-profile-update-button' onClick={copyToClipboard}>{'Copy Link'}</button>
             {copySuccess && <p style={{fontSize:12,color:'#22762B'}}>{copySuccess}</p>}
            </div>
        </Row>
    </div>
  )
}

export default CommForDashboard