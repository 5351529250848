import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './components/Main';
import MainMob from './mob/Main'
import './App.css';
import { useDispatch,useSelector } from 'react-redux';
import Home from './mob/components/pages/home/Home';

function App() {
  const [size,setSize] = useState([]);
  const user = useSelector(state=>state.userInfo);
  const appInfo = useSelector(state=>state.appInfo);
  console.log(user,appInfo)

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  console.log("size=====>>>>>>",size)
  return (
    size[0]>768?
    <Main/>
    :
    <MainMob size={size}/>
  )
}

export default App