import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import PrivacyPolicy from "./PrivacyPolicy";
import TnC from "./TnC";
import { useLocation } from "react-router-dom";

function Legal() {
  const [isPrivacyPolicyVisible, setPrivacyPolicyVisible] = useState(true);
  return (
    <div style={{ width: "100vw", overflowX: "hidden", marginTop: 20 }}>
      <Row>
        <Col md="2" className="legal-button-toggle">
          <p
            onClick={() => setPrivacyPolicyVisible(true)}
            className={
              isPrivacyPolicyVisible ? "legal-button-active" : "legal-button"
            }
          >
            Privacy Policy
          </p>
          <p
            onClick={() => setPrivacyPolicyVisible(false)}
            className={
              !isPrivacyPolicyVisible ? "legal-button-active" : "legal-button"
            }
          >
            Terms and Conditions
          </p>
        </Col>
        <Col style={{height:'71vh',overflowY:'scroll'}} md="10" className="legal-docs-space">
          {isPrivacyPolicyVisible && <PrivacyPolicy />}
          {!isPrivacyPolicyVisible && <TnC />}
        </Col>
      </Row>
    </div>
  );
}

export default Legal;
