import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import Tools from "./Tools";
import Community from "./Community";
import { useLocation } from "react-router-dom";

function Explore() {
    const location = useLocation()
    let { redirectedTabId } = location?.state || "1"
  const [tabId, setTabId] = useState(redirectedTabId || "1");
  if(tabId=="1"){
    redirectedTabId="1"
  }
  const whichTabIsActive = redirectedTabId=="1"?[true, false, false]:[false,true,false];
  const [tabActive, setTabActive] = useState(whichTabIsActive);

  const activeTab = (id) => {
    setTabId(id);
    var newTabActive = [false, false, false];
    newTabActive[id - 1] = true;
    setTabActive(newTabActive);
  };
  console.log("tab active", tabActive);

  return (
    <div>
      <Row className="nfold-main-row">
        <Container>
          <Row>
            <Col
              md="3"
              style={{
                height: "73vh",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p className="science-comp-text">
                nFold
              </p>
              <p className="science-comp-desc">
              The nFold showcases the various tools we've developed and highlights our vibrant community. It represents the different facets of our journey and the innovative products we've created, folding them into a cohesive narrative. Explore nFold to learn more about our achievements and collaborations.
              </p>
            </Col>
            <Col
              md="9"
              style={{
                height: "73vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Nav
                  tabs
                  style={{ border: "none", marginBottom: 0, marginLeft: "7%" }}
                >
                  <NavItem>
                    <NavLink
                      className={tabActive[0] ? "active" : ""}
                      onClick={() => activeTab("1")}
                      href="#"
                      style={{
                        color: tabActive[0] ? "#22762B" : "#C2C2C2",
                        paddingLeft: 0,
                        fontWeight: 600,
                        border: "none",
                      }}
                    >
                      <h6 className="dashboard-nav-tabs">
                        &nbsp;Explore&nbsp;Tools&nbsp;
                      </h6>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={tabActive[1] ? "active" : ""}
                      onClick={() => activeTab("2")}
                      href="#"
                      style={{
                        color: tabActive[1] ? "#22762B" : "#C2C2C2",
                        paddingLeft: 0,
                        fontWeight: 600,
                        border: "none",
                      }}
                    >
                      <h6 className="dashboard-nav-tabs">
                        &nbsp; &nbsp;Connect to Community&nbsp;
                      </h6>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent style={{ paddingTop: 20,height:'60vh' }} activeTab={tabId}>
                  <TabPane tabId="1" className="nfold-tools-tab">
                    <Tools />
                  </TabPane>
                  <TabPane
                    tabId="2"
                    className="nfold-tools-tab"
                    // Ensure 65vh height
                  >
                  <Community/>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
}

export default Explore;
