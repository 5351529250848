import React from 'react'
import {Container,Row,Col} from 'reactstrap'

function About() {
    const allTeamMembers = [{
        name : 'Ashwini Chhatre',
        ideology:'Idea.Philosophy',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Ashwini_Chatre.jpg'
    },
    {
        name : 'Satya Prasanna',
        ideology:'Idea.Philosophy',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Satya.png'
    },
    {
        name : 'Abhijeet Parmar',
        ideology:'Concept.Design',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Abhijeet.jpg'
    },
    {
        name : 'Sanjoy Mondal',
        ideology:'Leader.Field',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Sanjoy.jpg'
    },
    {
        name : 'Bhumika Morey',
        ideology:'Spatial.Information',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Bhumika.jpg'
    },
    {
        name : 'Niharika Walia',
        ideology:'Design.Framework',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Niharika+Walia.jpg'
    },
    {
        name : 'Nitesh Saini',
        ideology:'DevOps.Framework',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/Nitesh+Saini.jpg'
    },
    {
        name : 'Siddharth Yadav',
        ideology:'DevOps.Framework',
        desc:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
        url : 'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/siddharth.png'
    },
    

]
  return (
    <>
    
    <center>

    <h6 className='team-page-heading'>Meet Our <span className='team-page-title'>Team</span></h6>
        </center>
         <div className='all-team-members-container'>
          
         <Row>
{
 allTeamMembers.map((teamMember)=>{
     return(
         <Col md="3">    
         <div className='team-member-box'>
         <div className='team-image-box'>
             <img className='team-image' src={teamMember.url} alt={teamMember.url}/>
         </div>
         <div className='team-desc-box'>
             <div className='team-member-desc-box'>
           <p className='team-member-title'>{teamMember.name}</p>
           <p className='team-member-desc'>{teamMember.ideology}</p>
           <p className='team-member-info'>{teamMember.desc}</p>
             </div>
         </div>
         </div>
</Col>
     )
 })
}
</Row>
</div>
<div style={{marginTop:170 }} className='all-team-members-container'>
          
          <Row>
 {
  allTeamMembers.map((teamMember)=>{
      return(
          <Col md="3">    
          <div className='team-member-box'>
          <div className='team-image-box'>
              <img className='team-image' src={teamMember.url} alt={teamMember.url}/>
          </div>
          <div className='team-desc-box'>
              <div className='team-member-desc-box'>
            <p className='team-member-title'>{teamMember.name}</p>
            <p className='team-member-desc'>{teamMember.ideology}</p>
            <p className='team-member-info'>{teamMember.desc}</p>
              </div>
          </div>
          </div>
 </Col>
      )
  })
 }
 </Row>
 </div>
 <center>
        <h6 style={{marginTop:150 }}  className='team-page-heading'><span className='team-page-title'>Partners</span></h6>
        </center>
        <div style={{display:'flex',justifyContent:'center'}}>
        <div style={{marginTop:80,marginBottom:100}} className='all-team-members-container'>
        <div className='team-member-box-partner'>
                    <div className='team-image-box-partner'>
                        <img className='team-image-partner' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/HP.png'} alt='Delhi University'/>
                    </div>
                    <div className='team-desc-box-partner'>
                        <div className='team-member-desc-box-partner'>
                      <p className='team-member-title-partner'>{'Himachal Pradesh'}</p>
                      <p className='team-member-desc-partner'>{'Forest Department'}</p>
                      {/* <p className='team-member-info'>{teamMember.desc}</p> */}
                        </div>
                    </div>
                    </div>
            </div>
            <div style={{marginTop:80,marginBottom:100}} className='all-team-members-container'>
        <div className='team-member-box-partner'>
                    <div className='team-image-box-partner'>
                        <img className='team-image-partner' src={'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Team-Images/DU.png'} alt='Delhi University'/>
                    </div>
                    <div className='team-desc-box-partner'>
                        <div className='team-member-desc-box-partner'>
                      <p className='team-member-title-partner'>{'Delhi University'}</p>
                      <br/>
                      {/* <p className='team-member-desc-partner'>{'Forest Department'}</p> */}
                      {/* <p className='team-member-info'>{teamMember.desc}</p> */}
                        </div>
                    </div>
                    </div>
            </div>
            
        </div>

</>
  )
}

export default About