import { getDetails } from "../services/UtilsApi";


export const handleGetDetails = (callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getDetails(callback)
      .then(async ({ data: response }) => {
        console.log("RES", response.body?.rows[0]);
        callback(response.body?.rows[0])
        dispatch({ type: "DISABLE_LOADING" });
      })
      .catch((err) => {
        // callback(err?.response?.data);
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
      });
  };