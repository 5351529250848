import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector,useDispatch } from 'react-redux'
import Dropdown from '../../../utils/customDropdown';
import {
    Button,
    Tooltip ,
    NavItem,
    TabPane,
    NavLink,
    Nav,
    TabContent,
    Container,
    Row,
    Col,
  } from "reactstrap";
import DataCollection from './DataCollection';
import SpeciesData from './SpeciesData';
function UserData() {
    const user = useSelector(state=>state.userInfo);
    const [allsites,SetAllSites]  = useState([]);
    const [selectedSite,setSelectedSite] = useState(null);
    const [speciesData,setSpeciesData] = useState(null);
    const [questionaireData,setQuestionaireData] = useState(null);
    const [tabId, setTabId] = useState("1");
    const whichTabIsActive = [true, false,];
    const [tabActive, setTabActive] = useState(whichTabIsActive);
    const activeTab = (id) => {
      setTabId(id);
      var newtTabActive = [false,false];
      newtTabActive[id-1] = true;
      setTabActive(newtTabActive);
    };
    useEffect(()=>{
     axios.post('https://lojdkr8991.execute-api.us-east-1.amazonaws.com/getData',{
        "getSitesOnly":true,
       "userId":'1072',
     })
     .then((resp)=>{
        let allsites = [];
        if(resp?.data?.body.length>0){
            resp?.data?.body.map((siteName)=>{
                allsites.push({value:siteName.fieldSite,label:siteName.fieldSite})
            })
        }
        SetAllSites(allsites);
     })
     .catch((err)=>{
        console.log("error in getting field sites",err);
     })
    },[])
    console.log("selected site", selectedSite)
    const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Montserrat, sans-serif",
        fontWeight:500,
      };
      const inputStyle={
        borderRadius: "15px",
        boxShadow: "none",
        marginTop:20,
        paddingLeft:0,
        height:10,
        fontWeight:500,
        width:'100%',
        color:'#0a3b0a',
        fontSize:14,
        border: '1.35px solid #079015',
      }
      const handleChange = (selectedOption) => {
        setSelectedSite(selectedOption.value)
      };
      useEffect(()=>{
        if(selectedSite!=null){
                axios.post('https://lojdkr8991.execute-api.us-east-1.amazonaws.com/getData',{
                   "getSitesOnly":false,
                  "userId":'1072',
                  "siteName":selectedSite
                })
                .then((resp)=>{
                   setSpeciesData(resp?.data?.body?.speciesData);
                   setQuestionaireData(resp?.data?.body?.questionairData);
                })
                .catch((err)=>{
                   console.log("error in getting field site data",err);
                })
        }
      },[selectedSite])
      console.log(questionaireData,speciesData)
  return (
    <>
    <center>
    <Dropdown inputStyle={inputStyle} placeholder={'select a field site'}  placeholderStyle={placeholderStyle} onChange={handleChange} options={allsites}/>
    
    {
        selectedSite?
        <>
        <Nav tabs style={{border:'none',marginBottom:0}}>
        <NavItem>
                      <NavLink
                        className={tabActive[1] ? "active" : ""}
                        onClick={() => activeTab("1")}
                        href="#"
                        style={{ color: tabActive[0]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none' }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;Species&nbsp;</h6>
                      </NavLink>
                    </NavItem>
                  <NavItem>
                      <NavLink
                        className={tabActive[2] ? "active" : ""}
                        onClick={() => activeTab("2")}
                        href="#"
                        style={{ color: tabActive[1]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none'  }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;Data&nbsp;</h6>
                      </NavLink>
                    </NavItem>
                    </Nav>
                    <TabContent style={{ paddingTop: 20,  }} activeTab={tabId}>
                  <TabPane tabId="1" style={{ marginBottom: 100 }}>
                    {
                        speciesData?
                        <SpeciesData speciesData={speciesData}/>
                        :
                        <p>No Data Found.</p>
                    }
                  </TabPane>
                  <TabPane tabId="2" style={{ marginBottom: 100 }}>
                    {
                        questionaireData ?
                        <DataCollection questionaireData={questionaireData}/>
                        :
                        <p>No Data Found.</p>
                    }
                    </TabPane>
                  </TabContent>
                  </>
                  :null
}
    </center>
    </>
  )
}

export default UserData