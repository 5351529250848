import { createAppUser, getAppuserSubmissions, requestAccess,getAppuserSubmission, getAppuserSubmissionAttachmentsPreview } from "../services/odkService";


export const handleCreateOdkUser = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return createAppUser(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "UPDATE_ODK_USER", payload: response });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleRequestFormAccess = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return requestAccess(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        dispatch({ type: "UPDATE_ODK_USER", payload: response.data });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };

  export const handleGetAppUserSubmissions = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getAppuserSubmissions(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        // dispatch({ type: "UPDATE_ODK_USER", payload: response.data });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };
  export const handleGetAppUserSubmission = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getAppuserSubmission(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        // dispatch({ type: "UPDATE_ODK_USER", payload: response.data });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };
  export const handleGetAppUserSubmissionAttachmentsPreview = (data, callback) => (dispatch) => {
    dispatch({ type: "ENABLE_LOADING" });
    return getAppuserSubmissionAttachmentsPreview(data)
      .then(async ({ data: response }) => {
        console.log("RES", response);
        callback(response);
        // dispatch({ type: "UPDATE_ODK_USER", payload: response.data });
        dispatch({ type: "SHOW_ERROR", payload: null });
        dispatch({ type: "DISABLE_LOADING" });
        if (callback) {
          dispatch({ type: "DISABLE_LOADING" });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR", err);
        dispatch({ type: "DISABLE_LOADING" });
        dispatch({ type: "SHOW_ERROR", payload: err });
      });
  };