import { request } from "./ApiCentral";

export const createAppUser = (data) =>{
    console.log(data, " odk data")
    return request(
        '/odk/create-app-user',
        {
          method: 'POST',
          data,
        },
        false,
        false,
      );
}

export const requestAccess = (data) =>{
  console.log(data, " odk data")
  return request(
      '/odk/user-form-access',
      {
        method: 'POST',
        data,
      },
      false,
      false,
    );
}
export const getAppuserSubmissions = (data) =>{
  console.log(data, " odk data")
  return request(
      '/odk/get-appUser-submissions',
      {
        method: 'POST',
        data,
      },
      false,
      false,
    );
}
export const getAppuserSubmission = (data) =>{
  console.log(data, " odk data")
  return request(
      '/odk/get-appUser-submission',
      {
        method: 'POST',
        data,
      },
      false,
      false,
    );
}
export const getAppuserSubmissionAttachmentsPreview = (data) =>{
  console.log(data, " odk data")
  return request(
      '/odk/get-appUser-submission-image-preview',
      {
        method: 'POST',
        data,
      },
      false,
      false,
    );
}

