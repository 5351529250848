import React from 'react'
import CommingSoon from '../../../utils/CommingSoon'

function AdvanceProcess() {
  return (
    <div>
      <CommingSoon/>
    </div>
  )
}

export default AdvanceProcess