import QRCode from "qrcode-generator";
import Base64 from "base-64";
import zlib from 'react-zlib-js'

export const getQR = (token,location) => {
    console.log("generating qr code....",location)
    const settings = {
      general: {
        server_url:location=='Gadchiroli'?
        `https://d2p3.space/v1/key/${token}/projects/1`
        :
          `https://d2p3.space/v1/key/${token}/projects/1`,
        constraint_behavior: "on_swipe",
      },
      admin: {
        edit_saved: false,
      },
      project: {
        name: "nCount Collect",
        icon: "n",
        color: "#124912",
      },
    };
    const settingsJSON = JSON.stringify(settings); 
    const compressedData = zlib.deflateSync(settingsJSON);
      const base64EncodedData = Base64.encode(compressedData.toString("binary"));
    const qr = QRCode(0, "H");
    qr.addData(base64EncodedData);
    qr.make();
    const qrCodeDataURL = qr.createDataURL(10, 10);

    return qrCodeDataURL;
  }