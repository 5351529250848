import React from 'react'
import { Button } from 'reactstrap'

function Community() {
  return (
    <div className='nfold-tools-tab-div'>
          <p className="nfold-community-tab-desc">
                      The nCount community comprises a diverse array of
                      stakeholders, each contributing uniquely to the platform's
                      mission of environmental monitoring and conservation.
                      Forest-dwelling communities are integral, leveraging their
                      traditional ecological knowledge to document local
                      biodiversity and protect their natural habitats. Citizen
                      scientists, driven by curiosity and a passion for nature,
                      contribute valuable data, making conservation accessible
                      to all. School and college students gain practical
                      experience and a deeper appreciation for nature through
                      hands-on involvement in scientific research. Nature
                      enthusiasts, including hikers and birdwatchers, channel
                      their love for the outdoors into meaningful conservation
                      efforts. Researchers and scientists utilize nCount's
                      advanced AI and computer vision capabilities for accurate
                      species identification and classification, supporting
                      their studies and enhancing scientific understanding.
                      Together, these stakeholders form a vibrant and
                      collaborative community, united by a shared commitment to
                      environmental stewardship and social well-being, driving
                      impactful change through collective action and advanced
                      technology.
                    </p>
                    <p className="nfold-community-tab-desc">
                   
The nCount community benefits from a comprehensive documentation portal designed to help users seamlessly navigate and utilize the app's features. This portal provides detailed guides, tutorials, and FAQs, ensuring that users can efficiently contribute to environmental monitoring and conservation efforts.
                    </p>
                    <a href='https://docs.ncount.in/' target='_'>
                    <Button className='homeButton' style={{marginTop:1,marginBottom:15,width:'fit-content'}}>Documentation Hub</Button>
                    </a>
                    <p className="nfold-community-tab-desc">
                    Additionally, nCount hosts an active discussion forum on Discourse, where users can engage in meaningful conversations, share insights, and seek assistance. This forum fosters a sense of community and collaboration, enabling participants to connect with like-minded individuals, discuss biodiversity-related topics, and enhance their understanding and impact through collective knowledge sharing.
                    </p>
                    <a href='https://forum.ncount.in/' target='_'>
                    <Button className='homeButton' style={{marginTop:1,marginBottom:15,width:'fit-content'}}>&nbsp;&nbsp;Discussions Forum&nbsp;&nbsp;</Button>
                    </a>
    </div>
  )
}

export default Community