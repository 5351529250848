/** Nitesh Saini
 * © All rights reserved
 */

import axios from 'axios';
import { useSelector } from 'react-redux';
import {store} from '../index';
console.log(store?.getState()?.userInfo,"store.........")
console.log()
// export const BASE_URL = 'http://localhost:8080/api/nCount';
export const BASE_URL = 'https://htj5xy3nrv.us-east-1.awsapprunner.com/api/nCount';
export const request = async (
    url,
    options,
    isHeader = true,
    authHeader,
    isMultiPartFormData = false,
) => {
    const client = axios.create({
        baseURL: BASE_URL + url,
        headers: {
            Authorization: authHeader,
            accept: 'application/json',
            'Content-Type': isMultiPartFormData
                ? 'multipart/form-data'
                : 'application/json',
        },
    });

    return client(options);
};
