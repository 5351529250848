import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import MobDataCollectionForms from "./MobDataCollectionForms";
import OpenCompagins from "./OpenCompagins";

function DataCollection() {
  const [tabId, setTabId] = useState("1");
  const whichTabIsActive = [true, false];
  const [tabActive, setTabActive] = useState(whichTabIsActive);
  const activeTab = (id) => {
    setTabId(id);
    var newtTabActive = [false, false, false, false, false];
    newtTabActive[id - 1] = true;
    setTabActive(newtTabActive);
  };
  return (
    <div>
      <Nav tabs style={{ border: "none", marginBottom: 0 }}>
        <NavItem>
          <NavLink
            className={tabActive[0] ? "active" : ""}
            onClick={() => activeTab("1")}
            href="#"
            style={{
              color: tabActive[0] ? "#22762B" : "#C2C2C2",
              paddingLeft: 0,
              fontWeight: 600,
              border: "none",
            }}
          >
            <h6 className="dashboard-nav-tabs">
              &nbsp; &nbsp;Open Campaigns
            </h6>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={tabActive[1] ? "active" : ""}
            onClick={() => activeTab("2")}
            href="#"
            style={{
              color: tabActive[1] ? "#22762B" : "#C2C2C2",
              paddingLeft: 0,
              fontWeight: 600,
              border: "none",
            }}
          >
            <h6 className="dashboard-nav-tabs">
              &nbsp; &nbsp;Targeted&nbsp;Campaigns
            </h6>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent style={{ paddingTop: 20 }} activeTab={tabId}>
        <TabPane tabId="1" style={{ marginBottom: 50 }}>
          <OpenCompagins/>
        </TabPane>
        <TabPane tabId="2" style={{ marginBottom: 50 }}>
          <MobDataCollectionForms/>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default DataCollection;
