import React, { useState } from 'react'
import { Col, Input, Row } from 'reactstrap'
import Dropdown from '../../utils/customDropdown'
import axios from 'axios';

export default function GapDataForm() {
    const [selectedForm, setSelectedForm] = useState(null);
    const [userName,setUsername] = useState('');
    const [fullName,setFullName] = useState('')
    const [country,setCountry] = useState('');
    const [sdg,setSDG] = useState('');
    const [extraDetail,setExtraDetails] = useState('')
    const inputStyle={
        boxShadow: "none",
        paddingLeft:0,
        border: '1px solid #22762B',
        width:'100%',
         borderRadius: '40px',
         marginBottom:15
      }
      const handleChange = (selectedOption) => {
        console.log(selectedOption)
         setSDG(selectedOption)
       };
       const placeholderStyle = {
         color: '#0a3b0a',
          // Custom placeholder font style
       };
       const sdgList = [
        { value: 'No Poverty', label: 'No Poverty' },
        { value: 'Zero Hunger', label: 'Zero Hunger' },
        { value: 'Good Health and Well-Being', label: 'Good Health and Well-Being' },
        { value: 'Quality Education', label: 'Quality Education' },
        { value: 'Gender Equality', label: 'Gender Equality' },
        { value: 'Clean Water and Sanitation', label: 'Clean Water and Sanitation' },
        { value: 'Affordable and Clean Energy', label: 'Affordable and Clean Energy' },
        { value: 'Decent Work and Economic Growth', label: 'Decent Work and Economic Growth' },
        { value: 'Industry, Innovation and Infrastructure', label: 'Industry, Innovation and Infrastructure' },
        { value: 'Reduced Inequalities', label: 'Reduced Inequalities' },
        { value: 'Sustainable Cities and Communities', label: 'Sustainable Cities and Communities' },
        { value: 'Responsible Consumption and Production', label: 'Responsible Consumption and Production' },
        { value: 'Climate Action', label: 'Climate Action' },
        { value: 'Life Below Water', label: 'Life Below Water' },
        { value: 'Life and Land', label: 'Life and Land' },
        { value: 'Peace, Justice and Strong Institutions', label: 'Peace, Justice and Strong Institutions' },
        { value: 'Partnerships For The Goals ', label: 'Partnerships For The Goals ' }
      ];
      const handleSendRequest = async() =>{
         
        await axios.post('https://otddu6pngb.execute-api.us-east-1.amazonaws.com/measurement_gaps',{
          userName,fullName,country,sdg,extraDetail
        })
        .then((resp)=>{
         setCountry('');
         setExtraDetails('');
         setFullName('');
         setUsername('');
         console.log(resp)
        })
        .catch((err)=>{
         console.log(err);
        })
     }
     
  return (
    <div>
        <Row>
<Col md="7">
        <p className='gap-data-form-title'>
        Need Discovery
        </p>
<p className='gap-data-form-desc'>

nCount represents a cutting-edge approach to large scale prosperity and social well-being, through environmental monitoring and conservation. nCount is specifically designed to engage a diverse range of users, including forest-dwelling communities, citizen scientists, school and college students, nature enthusiasts, and researchers. Through an intuitive and user-friendly interface, participants can actively contribute to the scientific understanding of biodiversity by documenting plant and animal species in their local ecosystems. nCount harnesses the power of Artificial Intelligence (AI) and computer vision to assist in the identification and classification of species from images, ensuring accurate and efficient data processing.

Through an intuitive and user-friendly interface, participants can actively contribute to the scientific understanding of biodiversity by documenting plant and animal species in their local ecosystems. nCount harnesses the power of Artificial Intelligence (AI) and computer vision to assist in the identification and classification of species from images, ensuring accurate and efficient data processing.



</p>
</Col>
<Col md="5" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<div className='gap-form-row'>
    <p className='gap-data-form-title-1'>Suggest the Gap</p>
    <input className='gap-data-form-input'  value={userName}
      onChange={(e)=>setUsername(e.target.value)} placeholder='Email ID/ Mobile Number'/>
    <input className='gap-data-form-input'     value={fullName}
           onChange={(e)=>setFullName(e.target.value)} placeholder='Full Name'/>
    <input className='gap-data-form-input'  value={country}
         onChange={(e)=>setCountry(e.target.value)} placeholder='Country'/>
    <Dropdown inputStyle={inputStyle} placeholderStyle={placeholderStyle} placeholder={"I want to contribute to"} options={sdgList} onChange={handleChange}/>
    <Input
    style={{borderRadius:10,height:70,paddingLeft:0,marginBottom:20}}
    className='gap-data-form-input'
      id="exampleText"
      placeholder="Describe the data information Gap"
      type="textarea"
      value={extraDetail}
      onChange={(e)=>setExtraDetails(e.target.value)}
    />
    <button className='gap-data-form-button' onClick={()=>handleSendRequest()}>Submit</button>
</div>
</Col>
        </Row>
    </div>
  )
}
