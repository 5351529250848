import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import editWhite from '../../../assets/icons/edit_white.png'
import Dropdown from '../../../utils/customDropdown';
import { handleUpdateProfile, handleUpdateProfileImage } from '../../../store/actions/profileAction';
const useHiddenFileInput = () => {
    const hiddenFileInput = useRef(null);
  
    return hiddenFileInput;
  };
function EditProfile() {
    const user = useSelector(state=>state.userInfo);
    const hiddenFileInput = useHiddenFileInput();
    const [uri, setUri] = useState("");
    const [base64, setBase64] = useState(null);
    const appInfo =useSelector(state=>state.appInfo);
    const [email,setEmail] =useState(user.email || '')
    const [bio,setBio] =useState('')
    const [organization,setOrganization] =useState(user.organization || null)
    const [organizationName,setOrganizationName] =useState(user.organizationName || '')
    const [address,setAddress] = useState(user.address || '');
    const [portfolio,setPortfolio] =useState(user.webURL || '')
    const dispatch = useDispatch();
    const navigate= useNavigate()
    useEffect(()=>{
        if(user?.userId==null){
         navigate('/mob-login')
        }
     },[user])
     const placeholderStyle = {
        color: '#000',
        fontSize:'11px',
        paddingLeft:'-15px',
        fontFamily: "Montserrat, sans-serif",
        fontWeight:500,
      };
      const inputStyle={
        borderRadius: "5px",
        boxShadow: "none",
        marginTop:15,
        padding:15,
        height:10,
        fontWeight:500,
        width:'100%',
        color:'#0a3b0a',
        fontSize:14,
        border: '1.35px solid #079015',
      }
     
  const organizations = [
    { value: 'Community', label: 'Community' },
    { value: 'Government', label: 'Government' },
    { value: 'Academic and Research', label: 'Academic and Research' },
    { value: 'Civil Society', label: 'Civil Society' },
    { value: 'Corporations', label: 'Corporations' },
    { value: 'Others', label: 'Others' }
  ];
  const handleChange = (selectedOption) => {
    setOrganization(selectedOption.value)
  };
  const updateProfile = () =>{
    console.log(user);
    let data = {
      token:user.authToken,
      name:user.name,
      phoneNumber:user.phoneNumber,
        email:email,
        organization:organization,
        organizationName:organizationName,
        address:address,
      webURL:portfolio
    };
    dispatch(
      handleUpdateProfile(data, (args) => {
        console.log("ARGS->", args);
        navigate('/mob/dashboard')
      })
    );
  }
  const handleClick = () => {
    const event = hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("Select files", selectedFile);
    convertToBase64(selectedFile);
  };
  const convertToBase64 = (image) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    console.log("base64 started....");
    reader.onload = () => {
      setBase64(reader.result);
      uploadImage(reader.result)
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const uploadImage = async (base64Data) => {
    let data = {
      phoneNumber: user.phoneNumber,
      base64Data: base64Data,
      token:user.authToken,
    };
    dispatch(
      handleUpdateProfileImage(data, (args) => {
        console.log("ARGS->", args);
        setBase64(null);
      })
    );
  };

  return (
    <div>
    <div className='mob-profile-edit-window'>
        <div style={{width:'80%'}}>
            <div style={{position:'relative'}}>   
                <center>
                <img onClick={handleClick} className='mob-profile-image' src={user?.userImageUri} />
        <input
              id="ProfilePicture"
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
              />
          {
              <img onClick={handleClick} className='mob-edit-profile-image' src={editWhite} />
}
              </center>            
              </div>

<Input className='login-pin-input mob-profile-input' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Email Id'/>
<Input  type="textarea" value={bio} onChange={(e) =>setBio(e.target.value)} className='login-pin-input mob-profile-textarea' placeholder='Bio'/>
<div>
<Dropdown  inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Choose organization"}/>
  </div>
  <Input value={organizationName} onChange={(e)=>setOrganizationName(e.target.value)} className='login-pin-input mob-profile-input' placeholder='Name Of The Organization'/>
  <Input value={address} onChange={(e)=>setAddress(e.target.value)} className='login-pin-input mob-profile-input' placeholder='Address'/>
  <Input value={portfolio} onChange={(e)=>setPortfolio(e.target.value)} className='login-pin-input mob-profile-input' placeholder='Digital Portfolio Link'/>
 <center>
    <div>
    <button className='mob-profile-update-button' onClick={()=>updateProfile()}>{appInfo?.loading?'Loading...':'Save'}</button>
  <button style={{marginLeft:20}} className='mob-profile-update-button' onClick={()=>navigate('/mob/dashboard')}>{'Cancel'}</button>
    </div>
 </center>
  </div>
        </div>
    </div>
  )
}

export default EditProfile