export const scintificToLocal = {
    "morel": "Gucchi",
    "rhododendron": "Buransh",
    "chir pine": "Chir",
    "senegalia catechu": "Khair",
    "berberis aristate": "Kashmal",
    "aesculus indica": "Khanor",
    "maidenhead fern": "Lungru",
    "indian gooseberry": "Amla",
    "wild mango": "Aam",
    "shorea robusta": "Sal",
    "eucalyptus": "Safeda",
    "lantana": "Fulnu",
    "eupatorium": "Kali Basuti",
    "himalayan birch": "Bhojpatra",
    "taxus baccata": "Rakhal",
    "picrorrhiza kurooa": "Karu",
    "jurinea macrocephala": "Dhoop",
    "sea buckthorn": "Sea Buckthorn",
    "saussurea costus": "Kuth",
    "arnebia benthami": "Ratanjot",
    "aconitum balfourii": "Patish",
    "himalayan trillium": "Nag Chatri",
    "dactylorhiza hatagirea": "Panja",
    "cedrus deodara": "Deodar",
    "quercus leucotrichophora": "Ban Oak",
    "dendrocalamus strictus": "Bans",
    "bambusa bamboo": "Maggar",
    "tree": "Tree",
    "grass": "Grass",
    "shrub": "Shrub",
    "herb": "Herb",
    "fungus": "Fungus",
    "tree_sal":"Sal",
    "tree_mahua":"Mahua",
    "tree_chironji":"Chironji",
    "tree_karanj":"Karanj",
    "tree_harad":"Harad",
    "tree_arjun":"Arjun",
    "tree_unknown":"Unknown"
};

export const scintificToScintific = {
    "morel": "Morel",
    "rhododendron": "Rhododendron",
    "chir pine": "Chir pine",
    "senegalia catechu": "Senegalia catechu",
    "berberis aristate": "Berberis aristate",
    "aesculus indica": "Aesculus indica",
    "maidenhead fern": "Maidenhead fern",
    "indian gooseberry": "Indian gooseberry",
    "wild mango": "Wild mango",
    "shorea robusta": "Shorea robusta",
    "eucalyptus": "Eucalyptus",
    "lantana": "Lantana",
    "eupatorium": "Eupatorium",
    "himalayan birch": "Himalayan birch",
    "taxus baccata": "Taxus baccata",
    "picrorrhiza kurooa": "Picrorrhiza kurooa",
    "jurinea macrocephala": "Jurinea macrocephala",
    "sea buckthorn": "Sea buckthorn",
    "saussurea costus": "Saussurea costus",
    "arnebia benthami": "Arnebia benthami",
    "aconitum balfourii": "Aconitum balfourii",
    "himalayan trillium": "Himalayan trillium",
    "dactylorhiza hatagirea": "Dactylorhiza hatagirea",
    "cedrus deodara": "Cedrus deodara",
    "quercus leucotrichophora": "Quercus leucotrichophora",
    "dendrocalamus strictus": "Dendrocalamus strictus",
    "bambusa bamboo": "Bambusa bamboo",
    "tree": "Tree",
    "grass": "Grass",
    "shrub": "Shrub",
    "herb": "Herb",
    "fungus": "Fungus",
    "tree_sal":"Sal",
    "tree_mahua":"Mahua",
    "tree_chironji":"Chironji",
    "tree_karanj":"Karanj",
    "tree_harad":"Harad",
    "tree_arjun":"Arjun",
    "tree_unknown":"Unknown"
}