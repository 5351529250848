import React, { useState,useRef,useEffect } from 'react'
import {
    Button,
    Tooltip ,
    NavItem,
    TabPane,
    NavLink,
    Nav,
    TabContent,
    Container,
    Row,
    Col,
  } from "reactstrap";
import UserData from './UserData';
function Main() {
    const [tabId, setTabId] = useState("1");
    const whichTabIsActive = [true, false,];
    const [tabActive, setTabActive] = useState(whichTabIsActive);
    const activeTab = (id) => {
      setTabId(id);
      var newtTabActive = [false,false, false, false,false];
      newtTabActive[id-1] = true;
      setTabActive(newtTabActive);
    };
  return (
    <div>
    <Nav tabs style={{border:'none',marginBottom:0}}>
                  <NavItem>
                      <NavLink
                        className={tabActive[0] ? "active" : ""}
                        onClick={() => activeTab("1")}
                        href="#"
                        style={{ color: tabActive[0]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none'  }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;User&nbsp;Data</h6>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={tabActive[1] ? "active" : ""}
                        onClick={() => activeTab("2")}
                        href="#"
                        style={{ color: tabActive[1]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none' }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;Map&nbsp;Visualization</h6>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={tabActive[2] ? "active" : ""}
                        onClick={() => activeTab("3")}
                        href="#"
                        style={{ color: tabActive[2]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none' }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;Plot&nbsp;Visualization</h6>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={tabActive[3] ? "active" : ""}
                        onClick={() => activeTab("4")}
                        href="#"
                        style={{ color: tabActive[3]?"#22762B":"#C2C2C2", paddingLeft: 0,fontWeight:600,border:'none' }}
                      >
                        <h6 style={{ fontWeight: 600,fontSize:15 }}>&nbsp; &nbsp;IFRI&nbsp;</h6>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent style={{ paddingTop: 20,  }} activeTab={tabId}>
                  <TabPane tabId="1" style={{ marginBottom: 100 }}>
                    <UserData/>
                  </TabPane>
                  <TabPane tabId="2" style={{ marginBottom: 10 }}>
                  <iframe
        src="https://layers.ncount.in/layers/" // Replace with your desired URL
        title="Description of iframe content"
        width={'100%'}
        style={{ border: 'none',height:'60vh',overflowY:'scroll'}}
      />
                  </TabPane>
                  <TabPane tabId="3" style={{ marginBottom: 10 }}>
                  <iframe
        src="https://comf.ncount.in/plot-visualization/" // Replace with your desired URL
        title="Description of iframe content"
        width={'100%'}
        style={{ border: 'none',height:'60vh',overflowY:'scroll'}}
      />
                  </TabPane>
             <TabPane tabId="4" style={{ marginBottom: 10,}}>
  <iframe
    src="https://ifri.ncount.in/ifri/" // Replace with your desired URL
    title="Description of iframe content"
    width={'100%'} // Set this larger than the TabPane width
    style={{ border: 'none', height: '60vh', overflowY: 'scroll' }}
  />
</TabPane>
                  </TabContent>
    
        </div>
      )
    }

export default Main