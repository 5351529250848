import React, { useState,useEffect,useRef, useLayoutEffect} from 'react'
import { Container,Col,Row, Input,Button} from 'reactstrap'
import profileImage from '../../assets/icons/userDemo.png'
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../utils/customDropdown';
import editIcon from '../../assets/icons/edit.png'
import editWhite from '../../assets/icons/edit.png'
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { handleUpdateProfile, handleUpdateProfileImage } from '../../store/actions/profileAction';
import { getQR } from '../../utils/GenerateQR';
const useHiddenFileInput = () => {
  const hiddenFileInput = useRef(null);

  return hiddenFileInput;
};
function Profile() {
  const dispatch = useDispatch();
  const hiddenFileInput = useHiddenFileInput();
  const [uri, setUri] = useState("");
  const [base64, setBase64] = useState(null);
  const user = useSelector(state=>state.userInfo);
  const appInfo =useSelector(state=>state.appInfo);
  const [email,setEmail] =useState(user.email || '')
  const [bio,setBio] =useState('')
  const [organization,setOrganization] =useState(user.organization || null)
  const [organizationName,setOrganizationName] =useState(user.organizationName || '')
  const [address,setAddress] = useState(user.address || '');
  const [portfolio,setPortfolio] =useState(user.webURL || '')
  const [modal, setModal] = useState(false);
  const [size,setSize] = useState([]);
  const navigate= useNavigate()
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  console.log("size=====>>>>>>",size)

  const toggle = () =>{
    GenerateQR(user.odkToken);
    setModal(!modal)
  };
  useEffect(()=>{
    if(user?.userId==null){
     navigate('/login')
    }
 },[user])

  const [isEditTrue,setEditTrue] = useState(false);
  const placeholderStyle = {
    color: '#000',
    fontSize:size[0]>1300 ? '15px':'11px',
    paddingLeft:size[0]>1300 ? '-25px' :'-15px',
    fontFamily: "Montserrat, sans-serif",
    fontWeight:500,
  };
  const inputStyle={
    borderRadius: "15px",
    boxShadow: "none",
    marginTop:20,
    paddingLeft:0,
    height:10,
    fontWeight:500,
    width:size[0]>1300 ? '290px':'200px',
    color:'#0a3b0a',
    fontSize:14,
    border: '1.35px solid #079015',
  }

  const handleLogout =() =>{
    dispatch({ type: "LOG_OUT"});
  }

  // 

  const organizations = [
    { value: 'Community', label: 'Community' },
    { value: 'Government', label: 'Government' },
    { value: 'Academic and Research', label: 'Academic and Research' },
    { value: 'Civil Society', label: 'Civil Society' },
    { value: 'Corporations', label: 'Corporations' },
    { value: 'Others', label: 'Others' }
  ];
  const handleChange = (selectedOption) => {
    setOrganization(selectedOption.value)
  };
  const updateProfile = () =>{
    console.log(user);
    let data = {
      token:user.authToken,
      name:user.name,
      phoneNumber:user.phoneNumber,
        email:email,
        organization:organization,
        organizationName:organizationName,
        address:address,
      webURL:portfolio
    };
    dispatch(
      handleUpdateProfile(data, (args) => {
        console.log("ARGS->", args);
        setEditTrue(false);
      })
    );
  }
  const handleClick = () => {
    const event = hiddenFileInput.current.click();
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("Select files", selectedFile);
    convertToBase64(selectedFile);
  };
  const convertToBase64 = (image) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    console.log("base64 started....");
    reader.onload = () => {
      setBase64(reader.result);
      uploadImage(reader.result)
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const uploadImage = async (base64Data) => {
    let data = {
      phoneNumber: user.phoneNumber,
      base64Data: base64Data,
      token:user.authToken,
    };
    dispatch(
      handleUpdateProfileImage(data, (args) => {
        console.log("ARGS->", args);
        setBase64(null);
      })
    );
  };

  const GenerateQR = (token) => {
    setUri(getQR(token,user?.location));
  };
  return (
    <div className='profile-section' style={{overflowX:'hidden'}}>
      <center>

      <div className='profile-image-circle'>
        <div style={{position:'relative'}}>
          <img onClick={isEditTrue?handleClick:null} className='profile-image' src={user?.userImageUri} />
          <input
              id="ProfilePicture"
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
          {
            isEditTrue ?
          <img onClick={handleClick} className='edit-profile-image' src={editWhite} />
          :null
}
        </div>
          </div>
          {
            isEditTrue
            ?
            (
<div className='profile-edit-window'>
<Input className='login-pin-input profile-input' value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Email Id'/>
<Input  type="textarea" value={bio} onChange={(e) =>setBio(e.target.value)} className='login-pin-input profile-textarea' placeholder='Bio'/>
<div style={{width:200}}>
<Dropdown  inputStyle={inputStyle}  placeholderStyle={placeholderStyle} onChange={handleChange} options={organizations} placeholder={"Choose organization"}/>
  </div>
  <Input value={organizationName} onChange={(e)=>setOrganizationName(e.target.value)} className='login-pin-input profile-input' placeholder='Name Of The Organization'/>
  <Input value={address} onChange={(e)=>setAddress(e.target.value)} className='login-pin-input profile-input' placeholder='Address'/>
  <Input value={portfolio} onChange={(e)=>setPortfolio(e.target.value)} className='login-pin-input profile-input' placeholder='Digital Portfolio Link'/>
  <Button className='profile-update-button' onClick={()=>updateProfile()}>{appInfo?.loading?'Loading...':'Submit'}</Button>
  </div>
            )
            :
            <div>
              <div className='profile-info'>
                   <p className='profile-name-info'>{user?.name}</p>
                   <p className='profile-general-info'>{user?.email}</p>
                   <p className='profile-general-info'>{user?.organization},{user?.organizationName}</p>
                   <p className='profile-general-info'>{user?.address}</p>
                   <p className='profile-general-info' style={{marginBottom:30}}>{user?.webURL}</p>
              </div>
              <Button className='profile-edit-button' onClick={()=>setEditTrue(true)}>Edit <span><img className='profile-edit-icon' src={editIcon}/></span></Button>
            </div>
          }

        </center>
        <div>
              {user?.odkToken &&
                <p className='profile-qr-code' onClick={toggle}>Click to see your QR code</p>}
              <p className='signIn-button profile-sign-out mb-2' style={{width:170}} onClick={()=>handleLogout()}>Sign Out</p>
             </div>
              
     
      <Modal style={{width:250,padding:0}} centered isOpen={modal} toggle={toggle}>
        <ModalBody>
          <center>
      <img src={uri} style={{height:200,width:200}}/>
       <div style={{display:'flex',justifyContent:'space-between'}}>
       <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Save QR</p>
        </a>
        <a href={uri} style={{textDecoration:'none'}} download="nCount-ODK-QRCode.jpg">
        <p className='qr-text'>Download QR</p>
              </a>
       </div>
          </center>
        </ModalBody>
      </Modal>
   
    </div>
  )
}

export default Profile