import React from 'react'
import marker from '../../../../assets/icons/greenMarker.png';
import { Col, Row } from 'reactstrap';
import CommingSoon from '../../../../components/CommingSoon';

function Events() {
  const allEvents =[{},{},{}]
  return (
//     <div className='mob-event-main-div'>
//       <Row style={{justifyContent:'center'}}>
//         {
//           allEvents.map((eventCard) =>{
//            return (
//             <Col md="4" sm="6" xs="9">
//       <div className='mob-event-div'>
//         <div>
//           <p className='mob-event-div-date'>21 </p>
//          <p className='mob-event-div-month'> May ‘24</p>
//          <p className='mob-event-div-name'>Name of the event</p>
//         </div>
//          <div>
//           <center>
//           <img src={marker} className='mob-event-div-marker'/>
//           </center>
//           <p className='mob-event-div-place-name'>Place Name</p>
//          </div>
//       </div>
// </Col>
//            )
//           })
//         }

//       </Row>
//     </div>
<div style={{width:'100vw',overflowX:'hidden'}}><CommingSoon/></div>
  )
}

export default Events