import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import gap_table_demo_1 from '../../assets/misc/demo-gap-table.png';
import gap_table_demo_2 from '../../assets/misc/demo-gap-table-2.png'; 

function GapTable() {
    const [isUpcomingEventsVisible,setUpcomingEventsVisible] = useState(true);
    const upcomingEvents= [
        {
          img: gap_table_demo_1,
          title:"Gap Table: SPA, Delhi",
          desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
          buttonUrl:'/'
        },
        {
            img: gap_table_demo_2,
            title:"Gap Table: Delhi University",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_1,
            title:"Gap Table: SPA, Delhi",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_2,
            title:"Gap Table: Delhi University",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_1,
            title:"Gap Table: SPA, Delhi",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        }
    ]
    const pastEvents= [
        {
          img: gap_table_demo_1,
          title:"Gap Table: SPA, Delhi",
          desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
          buttonUrl:'/'
        },
        {
            img: gap_table_demo_2,
            title:"Gap Table: Delhi University",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_1,
            title:"Gap Table: SPA, Delhi",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_2,
            title:"Gap Table: Delhi University",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        },
        {
            img: gap_table_demo_1,
            title:"Gap Table: SPA, Delhi",
            desc:"This upcoming event will focus on the impact oftechnology on employment and the economy. Topics will include automation, AI ethics, and the gig economy",
            buttonUrl:'/'
        }
    ]
  return (
    <div style={{width:'100vw',overflowX:'hidden'}}>
        <Row className='mt-5' style={{alignItems:'flex-end'}}>
            <Col md="4" className='mt-5'>
            <div className='gap-table-col-1-div-1'>
            <button onClick={()=>setUpcomingEventsVisible(true)} className={isUpcomingEventsVisible ?'gap-table-col-1-div-1-button':'gap-table-col-1-div-1-button-disable'}>Upcoming Events</button>
            <button onClick={()=>setUpcomingEventsVisible(false)} className={!isUpcomingEventsVisible ?'gap-table-col-1-div-1-button':'gap-table-col-1-div-1-button-disable'}>Past Events</button>
            </div>
            <p className='gap-table-col-1-div-1-title'>
            GAP Table Workshops: Bridging the Gaps, Shaping the Future
            </p>
            <p className='gap-table-col-1-div-1-desc'>
            Discover our impactful GAP Table workshops, where global thought leaders and changemakers unite to identify and address critical gaps in society. 
            Explore highlights from our past events, featuring discussions on climate change, economic inequality, and more. 
            </p>
            </Col>
            <Col md="8">
            <div className='gap-table-col-2-div-main'>

                {
                    isUpcomingEventsVisible && upcomingEvents.map((event)=>{
                        return(
              <div className='gap-table-col-2-div-1'>
                <img src={event.img} className='gap-table-col-2-div-1-image'/>
                <p className='gap-table-col-2-div-1-title'>{event?.title}</p>
                <p className='gap-table-col-2-div-1-desc'>
                    {event?.desc}
                </p>
                <button className='gap-table-col-2-div-1-button'>Know More</button>
              </div>          
                        )
                    })
                }
                {
                    !isUpcomingEventsVisible && pastEvents.map((event)=>{
                        return(
              <div className='gap-table-col-2-div-1'>
                <img src={event.img} className='gap-table-col-2-div-1-image'/>
                <p className='gap-table-col-2-div-1-title'>{event?.title}</p>
                <p className='gap-table-col-2-div-1-desc'>
                    {event?.desc}
                </p>
                <button className='gap-table-col-2-div-1-button'>Know More</button>
              </div>          
                        )
                    })
                }
            </div>
            </Col>
        </Row>
    </div>
  )
}

export default GapTable