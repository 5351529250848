import React from 'react'
import { Row } from 'reactstrap'
import logo from '../../../../assets/logos/Header Logo.png'
import langIcon from '../../../../assets/icons/langIcon.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
function Header() {
  const user= useSelector(state=>state.userInfo);
  return (
    <div style={{padding:10}}>
        <Row className='mob-header-section'>
          <img src={logo} className='header-mob-logo'/>  
          <p style={{width:'fit-content',marginBottom:0}}>
            <img src={langIcon} style={{width:30,marginRight:10}}/>
          {
            user?.userId==null ? 
            <Link to="/mob-login">
          <button className='signIn-button mob-sigin-button'>Sign in</button>
          </Link>
          :
          <Link to="/mob-login">
          <img src={user?.userImageUri} style={{width:30,marginRight:10,borderRadius:20,height:30}}/>
          </Link>
          }
          {/* </div> */}
          </p>
        </Row>
    </div>
  )
}

export default Header