export const convertCsvToGeoJson = (location_data) => {
    const geoJson = {
        type: 'FeatureCollection',
        features: [],
    };

    location_data.forEach((row) => {
        var parsedLatitude=null;
        var parsedLongitude=null;
        if (row.speciesLocation.length > 0) {
            if(row.speciesLocation.length>1){
                parsedLatitude = parseFloat(row.speciesLocation[0]);
                parsedLongitude=parseFloat(row.speciesLocation[1]);
            }
            else{
                const location = row.speciesLocation[0];
                const [latitude, longitude] = location.split(' ');
                parsedLatitude = parseFloat(latitude);
                parsedLongitude = parseFloat(longitude);

            }

            if (!isNaN(parsedLatitude) && !isNaN(parsedLongitude)) {
                const feature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [parsedLongitude, parsedLatitude],
                    },
                    properties: {
                        speciesName: row.speciesName,
                        speciesType: row.speciesType,
                        submissionDate: row.submissionDate,
                    },
                };
                geoJson.features.push(feature);
            } else {
                console.warn(`Invalid location: ${row.speciesLocation} for species: ${row.speciesName}`);
            }
        }
    });

    return geoJson;
};
