import React, { useState } from "react";
import { useSelector } from "react-redux";
import GS_Data_Collection_Forms from "./GS_Data_Collection_Forms";
import GS_OdkInfo from "./GS_OdkInfo";

function GS_DataCollection() {
  const user = useSelector((state) => state.userInfo);
  const [userWantInfoAgain, setUserWantInfoAgain] = useState(false);
  console.log(userWantInfoAgain)
  return (
    <>
      {userWantInfoAgain ? (
        <GS_OdkInfo userWantInfoAgainFn={setUserWantInfoAgain} userWantInfoAgain={userWantInfoAgain}/>
      ) : user?.isTrialFormCompleted ? (
        <GS_Data_Collection_Forms userWantInfoAgain={setUserWantInfoAgain}/>
      ) : (
        <GS_OdkInfo userWantInfoAgainFn={setUserWantInfoAgain} userWantInfoAgain={userWantInfoAgain}/>
      )}
    </>
  );
}

export default GS_DataCollection;
