export const getColorForSpecies = (speciesName) => {
    if (!speciesName || speciesName=="Unknown") {
        return '#000000'; // Return a default color if speciesName is null or undefined
    }

    let hash = 0;
    for (let i = 0; i < speciesName.length; i++) {
        hash = speciesName.charCodeAt(i) + ((hash << 5) - hash);
    }

    const color = '#' + 
                  ((hash >> 24) & 0xFF).toString(16).padStart(2, '0') +
                  ((hash >> 16) & 0xFF).toString(16).padStart(2, '0') +
                  ((hash >> 8) & 0xFF).toString(16).padStart(2, '0') +
                  (hash & 0xFF).toString(16).padStart(2, '0');

    return color.slice(0, 7); // Return a valid hex color
};
