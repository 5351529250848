import React, { useState,useEffect} from 'react'
import { Button, Col, Row } from 'reactstrap'
import GettingStarted from './GettingStarted'
import MynCount from './MynCount'
import AdvanceProcess from './AdvanceProcess'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';

function LandingPage() {
    const [pageActive,setPageActive] = useState(-1)
    const dispatch = useDispatch();
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [mobileCompany,setMobileCompany] = useState(null);
    const [mobileModel,setMobileModel] = useState(null);
    const [mobileInfoUpdatingLoadingTrue,setmobileInfoUpdatingLoadingTrue] = useState(false);
    const user = useSelector(state=>state.userInfo);
    // console.log("user from landing page", user)
    const updateMobileInfo = async() =>{
      setmobileInfoUpdatingLoadingTrue(true);
      await axios.post('https://9abrwrkc5a.execute-api.us-east-1.amazonaws.com/mobile-info',
        {
          mobile_company:mobileCompany,
          mobile_model:mobileModel,
          phoneNumber:user?.phoneNumber
        }
      )
      .then((resp)=>{
        // console.log("mobile_model data",resp?.data)
        dispatch({ type: "UPDATE_MOBILE_INFO",payload: mobileCompany});
        setmobileInfoUpdatingLoadingTrue(false)
        toggle()
      })
      .catch((err)=>{
        console.log('something went wrong',err);
        setmobileInfoUpdatingLoadingTrue(false)
      })
    }

    useEffect(()=>{
      if(!user?.mobile_company){
        toggle();
      }
    },[])
  return (
    <div className='dashboard-landing-page'>
        <Row>
            <Col md="3">
        <div className='dashboard-landing-page-buttons'>
      <p className={pageActive==0 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"}  onClick={()=>setPageActive(0)}>Getting Started</p>
      <p className={pageActive==1 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"} onClick={()=>setPageActive(1)}>My nCount</p>
      <p className={pageActive==2 ?"landing-page-button-active landing-page-buttons" :"landing-page-buttons"} onClick={()=>setPageActive(2)} style={{padding:10}}>Advanced process Integrations</p>
        </div>
            </Col>
            <Col md="9">
                 {
                    pageActive == 0 && 
                    <GettingStarted/>
                 }
                 {
                    pageActive == 1 && 
                    <MynCount/>
                 }
                 {
                    pageActive == 2 && 
                    <AdvanceProcess/>
                 }
            </Col>
        </Row>
        {/* <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <center>
<h6 className="mt-3 mb-4">Mobile Camera Specification Info</h6>
        <input
            className="login-pin-input profile-input mt-5 mb-4"
            style={{width:'100%',borderRadius:10}}
            value={mobileCompany}
            onChange={(e)=>setMobileCompany(e.target.value)}
            placeholder="Mobile Company Names"
            />
              <input
              value={mobileModel}
              onChange={(e)=>setMobileModel(e.target.value)}
            className="login-pin-input profile-input mt-5 mb-2"
            style={{width:'100%',borderRadius:10}}
            placeholder="Mobile Company Model"
            />
              <button
            className="profile-update-button mt-3 mb-1"
            style={{paddingTop:5,paddingBottom:5,paddingLeft:15,paddingRight:15,color:'white'}}
            onClick={updateMobileInfo}
          >
            {mobileInfoUpdatingLoadingTrue ? 'Loading':'Submit'}
          </button>

            </center>
        </ModalBody>
        <hr/>
        <center>
          <p style={{fontSize:13,fontWeight:600,marginBottom:5,marginTop:-5}}>Disclaimer</p>
        </center>
        <p style={{fontSize:12,textAlign:'left',padding:'5px 10px'}}>By sharing your phone's model and brand information, we ensure that no personal data is collected from your device. This information is strictly used for improving our computational models.
         We do not access or store any sensitive data from your device. Your privacy is our priority, and we are committed to safeguarding your personal information. 
         </p>
      </Modal> */}
    </div>
  )  
}

export default LandingPage