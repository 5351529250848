import React from 'react'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from './components/pages/home/Home';
import LandingPage from './components/pages/home/LandingPage';
import Header2 from './components/pages/header/Header2';
import Header from './components/pages/header/Header';
import MobFooter from './components/footer/Footer';
import Events from './components/pages/events/Events';
import Login from './components/pages/login/Login';
import Profile from './dashboard/profile/Profile';
import EditProfile from './dashboard/profile/EditProfile';
import Stories from './components/stories/Stories';
import Sustainability from './components/sustainability/Sustainability';
import CommForDashboard from './CommForDashboard';

function Main({size}) {
  return (
        <BrowserRouter>
        <Header/>
     <Routes>
     <Route path="/" element={<LandingPage/>}/>
     <Route path="/mob/events" element={<Events/>}/>
     <Route path="/mob-login" element={<Login/>}/>
     <Route path="/mob/dashboard" element={<Profile/>}/>
     <Route path="/mob/edit-profile" element={<EditProfile/>}/>
     <Route path = "/mob/stories" element={<Stories/>}/>
     <Route path = "/mob/sustainability" element={<Sustainability/>}/>
     <Route path="/dashboard" element = {<CommForDashboard/>}/>
     </Routes>
     <Header2/>
     <MobFooter/>
        </BrowserRouter>
  )
}

export default Main