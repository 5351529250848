import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";

function NeedDicoveryComp() {
  const video1ref = useRef();
  useEffect(() => {
    if (video1ref.current) {
      video1ref.current.play().catch(error => {
        console.log("Autoplay failed:", error);
      });
    }
  }, []);

  return (
    <>
      <div style={{width:'100vw',overflowX:'hidden'}}>
      <video
       ref={video1ref}
        autoPlay
        muted
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <source src={'https://iofe-greenbase-bucket.s3.amazonaws.com/nCount/ncount-website-assests/Comp+1_8.mp4'}  type="video/mp4" />
      </video>
      </div>
    </>
  );
}

export default NeedDicoveryComp;
