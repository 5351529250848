import React, { useState } from "react";
import { Container, Row, Col, Button, NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import GDE from "../../../assets/nFold/GDE.png";
import IMU from "../../../assets/nFold/IMU.png";
import CommFor from "../../../assets/nFold/CommFor.png";
import Validator from "../../../assets/nFold/Validator.png";
import openData from "../../../assets/science/opendata.jpg";
import ion from "../../../assets/science/ion.png";
function Tools() {
    const allScienceComp = [
        {
          name: "GDE",
          desc: "The Google Earth Engine (GEE) – Data Extraction Engine is a Node.js-based tool developed by nCount to extract and localize large volumes of pixel-level information from GEE without encountering ‘user memory limit’ errors or computational issues.",
          url: GDE,
          href:"https://www.docs.ncount.in/docs/nCount%20Flow/Tools/GDEE/documentation"
        },
        {
          name: "IMU Data Capture",
          desc: "The IMU Data Capture is an Android mobile application designed to capture IMU sensor data and analyze the motion and orientation of the device in real-time.",
          url: IMU,
          href:"https://www.docs.ncount.in/docs/nCount%20Flow/Tools/IMU-Data-Capture/documentation"
        },
        {
          name: "CommFor",
          desc: "CommFor is an data collection solution addressing the lack of accessible, cost-effective tools for monitoring community forestry interventions and the scarcity of reliable multi-country data to inform policy and research.",
          url: CommFor,
          href:"https://www.docs.ncount.in/docs/nCount%20Flow/Tools/CommFor/quick-setup"
        },
        {
          name: "nCount Data Validator",
          desc: "nCount Data Validator is an React-based app which is designed to validate our ground collected data, helping to improve its accuracy and credibility. It ensures that the collected data is consistent and reliable for further analysis.",
          url: Validator,
          href:"https://www.docs.ncount.in/docs/nCount%20Flow/Tools/nCount%20Data%20Validator/quick-setup"
        },
      ];
  return (
    <div>
    <div className="nfold-tools-tab-div">
    <Row>
      {allScienceComp.map((comp) => {
        return (
          <Col md="3" className="science-comp-column">
            <div className='science-card-not-hovered'>
                  <img src={comp.url} alt={comp.name} className='science-comp-image'/>
                  <a href={comp?.href} target="_">  <Button className='science-comp-button-updated'>{comp.name}</Button></a>
                  </div>
            <div className="science-card-hovered">
              <img
                src={comp.url}
                alt={comp.name}
                className="science-comp-image-hovered"
              />
              <div>
                <p className="science-card-desc ml-2">{comp.desc}</p>
                <a href={comp?.href} target="_">
                <Button className="science-comp-button-updated">
                  {comp.name}
                </Button>
                </a>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  </div>
  </div>

  )
}

export default Tools