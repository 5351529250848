import React, { useEffect } from 'react'
import Profile from './Profile'
import { Container,Row,Col } from 'reactstrap'
import Header from './Header'
import Footer from '../navbar/Footer'
import LandingPage from './pages/LandingPage'
import { useSelector,useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom';
import {handleGetProfile} from '../../store/actions/profileAction'
import Main from './commfor/Main'


function Dashboard() {
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state=>state.userInfo);
  useEffect(()=>{
    if(user?.userId==null){
     navigate('/login')
    }
 },[user])
 console.log(user?.isCommForUser)
 useEffect(()=>{
  console.log("getting profile---------------->>>>>>>>>>>")
  let data = {
    token:user.authToken,
    phoneNumber:user.phoneNumber
  }
  dispatch(
    handleGetProfile(data, (args) => {
      console.log("ARGS->", args)
      if(args=="Forbidden"){
        dispatch({ type: "LOG_OUT"});
    navigate("/login")
      }
      
    })
  );
},[])
  return (
    <div className='Dashboard-main-container'>
          <Row>
            <Col md="9">
              <Header/>
              {
                user?.isCommForUser=="1" ?
                <Main/>
                :
                <LandingPage/>
              }
            </Col>
            <Col md="3">
              <div className='profile-sction-container'>
             <Profile/>
              </div>
            </Col>
          </Row>
    </div>
  )
}

export default Dashboard